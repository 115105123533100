import { gql } from '@apollo/client';

/*
    Queries
*/
export const GET_USER_QUERY = gql`
    query GetUser($email: String!) {
        getUser(email: $email) {
            loginEmail
            firstName
            lastName
            contactEmail
            phone
            settingsTitleMonitoringActive
            settingsTitleMonitoringEmailNotification
            settingsTitleMonitoringPhoneNotification
        }
    }
`;

/*
    Mutations
*/
export const CREATE_USER_MUTATION = gql`
    mutation createUser($email: String!) {
        createUser(email: $email) {
            loginEmail
            created
        }
    }
`;

export const UPDATE_USER_MUTATION = gql`
    mutation updateUser(
        $loginEmail: String!, 
        $contactEmail: String, 
        $firstName: String, 
        $middleName: String, 
        $lastName: String, 
        $phone: String, 
        $photoURL: String, 
        $settingsTitleMonitoringActive: Boolean, 
        $settingsTitleMonitoringEmailNotification: Boolean, 
        $settingsTitleMonitoringPhoneNotification: Boolean
    ) {
        updateUser(
            loginEmail: $loginEmail, 
            contactEmail: $contactEmail, 
            firstName: $firstName, 
            middleName: $middleName, 
            lastName: $lastName, 
            phone: $phone, 
            photoURL: $photoURL, 
            settingsTitleMonitoringActive: $settingsTitleMonitoringActive, 
            settingsTitleMonitoringEmailNotification: $settingsTitleMonitoringEmailNotification,
            settingsTitleMonitoringPhoneNotification: $settingsTitleMonitoringPhoneNotification
        ) {
            loginEmail
            contactEmail
            firstName
            middleName
            lastName
            phone
            settingsTitleMonitoringActive
            settingsTitleMonitoringEmailNotification
            settingsTitleMonitoringPhoneNotification
        }
    }
`;


export const UPDATE_TITLEMONITORING_ENABLED_MUTATION = gql`
    mutation updateUser($loginEmail: String!, $settingsTitleMonitoringActive: Boolean) {
        updateUser(loginEmail: $loginEmail settingsTitleMonitoringActive: $settingsTitleMonitoringActive) {
            loginEmail
            settingsTitleMonitoringActive
        }
    }
`;