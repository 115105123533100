import React from 'react'

function FeatureDescriptionPropertyReports() {
    return (
        <div className="flex flex-col items-center my-10 mx-5">
            <div className='max-w-screen-sm w-full text-center'>
                <h1>Property Reports</h1>
                <img
                    alt='Property Reports'
                    className='w-full my-5'
                    src='https://storage.googleapis.com/praxi-files-frontend/content/Illustration-PropertyReports.svg'
                />
                <p className='mt-5'>We provide easy online access to your property data, including ownership history, deed information, and more. This information can be valuable for a variety of purposes, such as tracking the value of your home over time, researching your property's history, or preparing to buy or sell a home.</p>
            </div>
        </div>
    )
}

export default FeatureDescriptionPropertyReports