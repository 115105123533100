import '../../styles/components.scss'

import React from 'react';

const HighlightedInfo = ({ title, value }) => {
    
    return (
        <div className='highlighted-info-field drop-shadow-md'>
            <h4 className='highlighted-info-title'>{title}</h4>
            <div className='highlighted-info-value'>{value}</div>
        </div>
    );
};

export default HighlightedInfo;