import "./Navbar.scss"
import React, { useEffect, useState, useRef } from "react";
import { useMediaQuery } from "react-responsive";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../firebase";
import { useNavigate } from 'react-router-dom'

import { SignInRouteButton } from "./widgets/SignInRouteButton";
import { SignUpRouteButton } from "./widgets/SignUpRouteButton";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { faBars } from '@fortawesome/free-solid-svg-icons'

const Navbar = () => {
    const isMobile = useMediaQuery({ maxWidth: 768 });
    const [authUser, setAuthUser] = useState(null);


    const navigate = useNavigate();

    const nav = (path) => {
        navigate(path);
    }


    useEffect(() => {
        const listen = onAuthStateChanged(auth, (user) => {
            if (user) {
                setAuthUser(user);
            } else {
                setAuthUser(null);
            }
        });
        return () => {
            listen();
        }
    }, []);


    const menuItems = [
        {
            name: "Explore",
            nav: () => nav("/explore"),
            submenu: [
                {
                    name: "Home Equity Investments",
                    nav: () => nav("/explore/home-equity-investments"),
                },
                // {
                //     name: "Home Improvement Solutions",
                //     nav: () => nav("/explore/home-improvement-solutions"),
                // },
                {
                    name: "Home Purchasing Solutions",
                    nav: () => nav("/explore/home-purchasing-solutions"),
                },
                // {
                //     name: "Interior Solutions",
                //     nav: () => nav("/explore/interior-solutions"),
                // }
            ]
        },
    ]

    return (
        <div>
            {isMobile ?
                <NavbarMobile authUser={authUser} menuItems={menuItems} /> :
                <NavbarDesktop authUser={authUser} menuItems={menuItems} />
            }
        </div>
    );
};

const NavbarDesktop = ({ authUser, menuItems }) => {
    const [showSubmenu, setShowSubmenu] = useState(false);

    const navigate = useNavigate();
    const navigateToProfile = () => {
        navigate("/profile");
    }
    const navigateToLandingPage = () => {
        navigate("/");
    }
    const navigateToDashboard = () => {
        navigate("/dashboard");
    }
    const navigateToFeatureTitleMonitoring = () => {
        navigate("/feature-title-monitoring");
    }
    const navigateToFeaturePropertyReports = () => {
        navigate("/feature-property-reports");
    }


    const toggleSubmenu = () => {
        // Toggle the submenu's visibility
        setShowSubmenu(!showSubmenu);
    }

    return (
        <div className="navbar-container drop-shadow-md">
            <div className="outer-content-container flex flex-row justify-between items-center">
                <div className="flex flex-row">

                    <span onClick={navigateToLandingPage}>
                        <img
                            src='https://storage.googleapis.com/praxi-files-frontend/content/praxilock-logo-blue.png'
                            alt="Logo"
                            className="navbar-logo"
                        />
                    </span>

                    <div className="flex flex-row items-center ml-10">
                        {/* Add onClick event listener for "Explore" */}
                        {authUser ?
                            <span>
                                <span className="ml-5 menu" onMouseEnter={toggleSubmenu} onMouseLeave={toggleSubmenu}>

                                    Explore <FontAwesomeIcon icon={faChevronDown} />

                                    {showSubmenu && (
                                        <ul className="drop-shadow-md submenu">
                                            {menuItems[0].submenu.map((item) => (
                                                <li key={item.name}>
                                                    <div onClick={() => item.nav()}>
                                                        {item.name}
                                                    </div>
                                                    {/* <a href={item.href}>{item.name}</a> */}
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </span>

                                <span className="ml-5 menu" onClick={navigateToFeatureTitleMonitoring}>
                                    Title Monitoring
                                </span>
                                <span className="ml-5 menu" onClick={navigateToFeaturePropertyReports}>
                                    Property Reports
                                </span>
                                <span className="ml-5 menu" onClick={navigateToDashboard}>
                                    Dashboard
                                </span>
                            </span>
                            :
                            <span>
                                <span className="ml-5 menu" onClick={navigateToFeatureTitleMonitoring}>
                                    Title Monitoring
                                </span>
                                <span className="ml-5 menu" onClick={navigateToFeaturePropertyReports}>
                                    Property Reports
                                </span>
                            </span>
                        }
                    </div>
                </div>


                <div className="inner-content-container-right">
                    <div>
                        {authUser ?
                            <div>
                                <button className="text-button" onClick={navigateToProfile}>Profile & Settings</button>
                            </div> :
                            <div>
                                <SignUpRouteButton />
                                <span className="ml-5"></span>
                                <SignInRouteButton />
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

const NavbarMobile = ({ authUser, menuItems }) => {
    const [showMenu, setShowMenu] = useState(false);
    const [showLoggedOutMenu, setShowLoggedOutMenu] = useState(false);
    // const open = Boolean(anchorEl);
    // const handleClick = (event) => {
    //     setAnchorEl(event.currentTarget);
    // };
    // const handleClose = () => {
    //     setAnchorEl(null);
    // };

    const navigate = useNavigate();
    const menuRef = useRef(null);
    const loggedOutMenuRef = useRef(null);


    const toggleMenu = (event) => {
        event.stopPropagation();
        setShowMenu(!showMenu);
    };

    const toggleLoggedOutMenu = (event) => {
        event.stopPropagation();
        setShowLoggedOutMenu(!showLoggedOutMenu);
    };

    const navigateToExplore = () => {
        navigate("/explore/home-equity-investments");
        closeMenu();
    };

    const navigateToProfile = () => {
        navigate("/profile");
        closeMenu();
    };

    const navigateToLandingPage = () => {
        navigate("/");
        closeMenu();
    };

    const navigateToDashboard = () => {
        navigate("/dashboard");
        closeMenu();
    };

    const navigateToFeatureTitleMonitoring = () => {
        navigate("/feature-title-monitoring");
        closeMenu();
    };

    const navigateToFeaturePropertyReports = () => {
        navigate("/feature-property-reports");
        closeMenu();
    };

    const closeMenu = () => {
        setShowMenu(false);
        setShowLoggedOutMenu(false);
    };

    // Add a click event listener to the document body
    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                closeMenu();
            }
            if(loggedOutMenuRef.current && !loggedOutMenuRef.current.contains(event.target)) {
                closeMenu();
            }
        };

        if (showMenu) {
            document.body.addEventListener("click", handleOutsideClick);
        }

        if(showLoggedOutMenu) {
            document.body.addEventListener("click", handleOutsideClick);
        }

        // Clean up the event listener when the component unmounts
        return () => {
            document.body.removeEventListener("click", handleOutsideClick);
        };
    }, [showMenu, showLoggedOutMenu]);

    return (
        <div className="flex flex-row justify-between items-center px-5 py-5 bg-white drop-shadow-md">
            
            <span onClick={authUser ? navigateToDashboard : navigateToLandingPage}>
                <img
                    src="https://storage.googleapis.com/praxi-files-frontend/content/praxilock-logo-blue.png"
                    alt="Logo"
                    className="navbar-logo"
                />
            </span>
            {authUser ? (
                <button className="text-button" onClick={toggleMenu}>
                    <FontAwesomeIcon icon={faBars} />
                </button>
            ) : (
                <div className="flex flex-row">
                    <SignUpRouteButton alternative={true} />
                    <span className="ml-4"></span>
                    <SignInRouteButton alternative={true} />
                    <button className="text-button" onClick={toggleLoggedOutMenu}>
                        <FontAwesomeIcon icon={faBars} />
                    </button>
                </div>

            )}

            {showLoggedOutMenu && (
                <div className="mobile-menu" ref={loggedOutMenuRef}>

                    <div className="mobile-menu-item w-full" onClick={navigateToFeatureTitleMonitoring}>
                        <button onClick={navigateToFeatureTitleMonitoring}>Title Monitoring</button>
                    </div>
                    <div className="mobile-menu-item w-full" onClick={navigateToFeaturePropertyReports}>
                        <button onClick={navigateToFeaturePropertyReports}>Property Reports</button>
                    </div>

                </div>
            )}

            {showMenu && (
                <div className="mobile-menu" ref={menuRef}>
                    <div className="mobile-menu-item w-full" onClick={navigateToDashboard}>
                        <button onClick={navigateToDashboard}>Dashboard</button>
                    </div>
                    <div className="mobile-menu-item w-full" onClick={navigateToFeatureTitleMonitoring}>
                        <button onClick={navigateToFeatureTitleMonitoring}>Title Monitoring</button>
                    </div>
                    <div className="mobile-menu-item w-full" onClick={navigateToFeaturePropertyReports}>
                        <button onClick={navigateToFeaturePropertyReports}>Property Reports</button>
                    </div>

                    <div className="mobile-menu-item w-full">
                        <button onClick={navigateToExplore}>{menuItems[0].name}</button>
                    </div>
                    <div className="mobile-menu-item w-full">
                        <button onClick={navigateToProfile}>Profile & Settings</button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Navbar;