import { gql } from '@apollo/client';

/*
    Queries
*/
export const GET_ADDRESSES_QUERY = gql`
    query getAddresses($userLoginEmail: String!) {
        getAddresses(userLoginEmail: $userLoginEmail) {
            id
            userLoginEmail
            created
            updated
            label
            streetAddress
            locality
            regionCode
            unit
            county
            countryCode
            postalCode
            postalCodeExt
            propertyMapUrl
        }
    }
`;

/*
    Mutations
*/
export const CREATE_ADDRESS_MUTATION = gql`
    mutation createAddressByFields($userLoginEmail: String!, 
                                $street: String!, 
                                $city: String!,
                                $state: String!,
                                $unit: String) {
        createAddressByFields(userLoginEmail: $userLoginEmail,
                            street: $street,
                            unit: $unit,
                            city: $city,
                            state: $state) {
            id
            userLoginEmail
            created
            updated
            label
            streetAddress
            locality
            regionCode
            unit
            county
            countryCode
            postalCode
            postalCodeExt
            propertyMapUrl
        }
    }
`;

export const DELETE_ADDRESS_MUTATION = gql`
    mutation deleteAddress($id: String!) {
        deleteAddress(id: $id) {
            id
            userLoginEmail
            created
            updated
            label
            streetAddress
            locality
            regionCode
            unit
            county
            countryCode
            postalCode
            postalCodeExt
            propertyMapUrl
        }
    }
`;

