
import './widgetstyles/SliderMenu.scss'
import React from "react";

import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

const SliderMenu = ({ menuItems }) => {
    const isMobile = useMediaQuery({ maxWidth: 768 });
    const navigate = useNavigate();

    const handleMenuItemClick = (to) => {
        navigate(to);
    }

    return (
        <div 
        className={isMobile ?
        'slidermenu-container-mobile':
        'slidermenu-container'}
        >
            {menuItems.map((item, index) => (
                <div className={item.active ? 'menu-item active active-tab-title' : 'menu-item tab-title'}
                    key={index}
                    onClick={() => handleMenuItemClick(item.to)}
                >
                    {item.name}
                </div>
            ))}
        </div>
    )
}

export default SliderMenu;
