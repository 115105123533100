import "./Footer.scss"

import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";

import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../firebase";

import { useNavigate } from 'react-router-dom';


const Footer = () => {
    const isMobile = useMediaQuery({ maxWidth: 768 });
    const navigate = useNavigate();
    const [authUser, setAuthUser] = useState(null);

    const navigateToAbout = () => {
        navigate("/about");
    }

    const navigateToContact = () => {
        navigate("/contact");
    }

    const navigateToHomeEquityInvestments = () => {
        navigate("/explore/home-equity-investments");
    }
    // const navigateToHomeImprovementSolutions = () => {
    //     navigate("/explore/home-improvement-solutions");
    // }

    // const navigateToInteriorSolutions = () => {
    //     navigate("/explore/interior-solutions");
    // }

    const navigateToHomePurchasingSolutions = () => {
        navigate("/explore/home-purchasing-solutions");
    }

    const navigateToFeatureTitleMonitoring = () => {
        navigate("/feature-title-monitoring");
    }

    const navigateToFeaturePropertyReports = () => {
        navigate("/feature-property-reports");
    }

    useEffect(() => {
        const listen = onAuthStateChanged(auth, (user) => {
            if (user) {
                setAuthUser(user);
            } else {
                setAuthUser(null);
            }
        });
        return () => {
            listen();
        }
    }, []);

    return (

        <div className={isMobile ?
            'flex flex-col-reverse bg-black w-full py-8 px-5 -z-10' :
            'flex flex-row bg-black w-full py-8 px-5 -z-10'}>
            <div className={isMobile ? 'mt-10' : ''}>
                <img
                    src='https://storage.googleapis.com/praxi-files-frontend/content/praxilock-logo-white.png'
                    alt="Logo"
                    className="footer-logo"
                />
                <p className="footer-text mr-3">© {new Date().getUTCFullYear()} Praxilock. All rights reserved.</p>
            </div>
            {
                authUser && (
                    <div className={isMobile ? 'mt-10' : "ml-20"}>
                        <p className="footer-header pb-5">EXPLORE</p>
                        <p className="footer-link" onClick={navigateToHomeEquityInvestments}>Home Equity Investments</p>
                        {/* <p className="footer-link" onClick={navigateToHomeImprovementSolutions}>Home Improvement Solutions</p>
                        <p className="footer-link" onClick={navigateToInteriorSolutions}>Interior Solutions</p> */}
                        <p className="footer-link" onClick={navigateToHomePurchasingSolutions}>Home Purchasing Solutions</p>
                    </div>)
            }

            <div className={isMobile ? 'mt-10' : "ml-20"}>
                <p className="footer-header pb-5">FEATURES</p>
                <p className="footer-link" onClick={navigateToFeatureTitleMonitoring}>Title Monitoring</p>
                <p className="footer-link" onClick={navigateToFeaturePropertyReports}>Property Reports</p>
            </div>

            <div className={isMobile ? '' : "ml-20"}>
                <p className="footer-header pb-5">COMPANY</p>
                <p className="footer-link" onClick={navigateToAbout}>About</p>
                <p className="footer-link" onClick={navigateToContact}>Contact</p>
                <div><a className="footer-link" href={process.env.REACT_APP_LINK_TERMS_OF_SERVICE}>Terms</a></div>
                {/* <div><a className="footer-link" href={process.env.REACT_APP_LINK_PRIVACY_STATEMENT}>Privacy</a></div> */}
            </div>
        </div>


    );
}

export default Footer;