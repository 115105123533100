import { gql } from '@apollo/client';

/*
    Queries
*/
export const GET_PROPERTY_DASHBOARDINFO_BY_ADDRESS_ID_QUERY = gql`
    query getAssessmentByAddressId($addressId: String!) {
        getAssessmentByAddressId(addressId: $addressId){
            id
            created
            updated
            unitArea
            unitLength
            valuationLabel
            valuationValue
            valuationValueHigh
            valuationValueLow
            taxAmount
            taxYear
            taxRateAreaCode
            legalDescriptions
            owner {
                names {
                    fullName
                    firstName
                    lastName
                }
                streetAddress
                locality
                postalCode
                regionCode
                ownershipStatusDescription
                ownershipStatusCode
            }
            primaryStructure {
                storiesCount
                yearBuilt
                yearRenovated
                foundationDescription
                elevator
                basementDescription
                exteriorWallDescription
                fireplace
                heatingDescription
                constructionDescription
                roofDescription
                styleDescription
                airConditioningDescription
                rooms
                bedrooms
                baths
                units
                livingArea
                parkingSpaces
                buildingLotRatio
                garageDescription
                numberOfBuildings
            }
        }

    }
`;
