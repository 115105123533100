import "../../styles/widgetStyles.scss"

import React from 'react'
import { useNavigate } from 'react-router-dom'

export const SignUpRouteButton = ({ className, alternative }) => {

    const navigate = useNavigate();

    const handleClick = () => {
        navigate("/signup");
    }

    return (
        <button
            className={alternative === true ?
                `${className} text-button-primary` :
                `${className} filled-button`}
            onClick={handleClick}>
            Sign Up
        </button>
    )
}
