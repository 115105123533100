// function that take a number and a unit (sqm, sqft, ft, m), and returns a number respectively in imperial unit
function convertToImperial(number, unit) {
    switch (unit) {
        case 'sqm':
            return number * 10.7639;
        case 'sqft':
            return number;
        case 'ft':
            return number;
        case 'm':
            return number * 3.28084;
        default:
            return number;
    }
}

// function that take a number and a unit (sqm, sqft, ft, m), and returns a number respectively in metric unit
function convertToMetric(number, unit) {
    switch (unit) {
        case 'sqm':
            return number;
        case 'sqft':
            return number * 0.092903;
        case 'ft':
            return number * 0.092903;
        case 'm':
            return number;
        default:
            return number;
    }
}

// function that takes a unit and returns the corresponding unit in imperial
function getImperialUnit(unit) {
    switch (unit) {
        case 'sqm':
            return 'sqft';
        case 'sqft':
            return 'sqft';
        case 'ft':
            return 'ft';
        case 'm':
            return 'ft';
        default:
            return unit;
    }
}

// function that takes a unit and returns the corresponding unit in metric
function getMetricUnit(unit) {
    switch (unit) {
        case 'sqm':
            return 'sqm';
        case 'sqft':
            return 'sqm';
        case 'ft':
            return 'm';
        case 'm':
            return 'm';
        default:
            return unit;
    }
}

module.exports = {
    convertToImperial,
    convertToMetric,
    getImperialUnit,
    getMetricUnit
};