import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { GET_PARTNERS_BY_CATEGORY_QUERY } from "../../graphql/PartnerOperations";
import { useQuery } from "@apollo/client";

import SliderMenu from "../../components/widgets/SliderMenu";
import PartnerQuickList from "../../components/partners/PartnerQuickList";

const HomeEquityInvestments = () => {
    const isMobile = useMediaQuery({ maxWidth: 768 });
    const category = "Home Equity Investments";
    const [partners, setPartners] = useState([]);

    const { data } = useQuery(GET_PARTNERS_BY_CATEGORY_QUERY, {
        variables: { category: category },
    });

    const menuItems = [
        { name: 'Home Equity Investments', to: '/explore/home-equity-investments', active: true },
        // { name: 'Home Improvement Solutions', to: '/explore/home-improvement-solutions', active: false },
        // { name: 'Interior Solutions', to: '/explore/interior-solutions', active: false },
        { name: 'Home Purchasing Solutions', to: '/explore/home-purchasing-solutions', active: false },
    ]

    useEffect(() => {
        if (data && data.getPartnersByCategory) {
            const partnersData = [];

            data.getPartnersByCategory.map((partner) => {
                partnersData.push({
                    logoSrc: partner.logoUrl500x200,
                    title: partner.name,
                    shortDescription: partner.shortDescription,
                    url: partner.affiliateUrl ? partner.affiliateUrl : partner.homepageUrl,
                });
                return partner;
            });
            setPartners(partnersData);

        }

    }, [data]);

    return (
        <div className={isMobile ?
            'flex flex-col py-10 px-5' :
            'flex flex-col items-center py-10 px-5'}>

            <SliderMenu menuItems={menuItems} />
            <h1 className={isMobile ?
                "text-left w-full"
                :'text-left w-full mt-10'}>Home Equity Investments</h1>
            <PartnerQuickList partners={partners} />
        </div>
    )
}

export default HomeEquityInvestments;
