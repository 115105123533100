// CustomSwitch.js
import React, { useState, useEffect } from 'react';
import './widgetstyles/Switch.scss'; // Import a CSS file for styling (you can customize this)

const Switch = ({ initialValue, onChange }) => {
    const [checked, setChecked] = useState(initialValue);

    const handleChange = () => {
        const newValue = !checked;
        setChecked(newValue);
        if (onChange) {
            onChange(newValue);
        }
    };

    useEffect(() => {
        setChecked(initialValue);
    }, [initialValue]);

    return (
        <div className={`switch ${checked ? 'on' : 'off'}`} onClick={handleChange}>
            <div className="slider" />
        </div>
    );
};

export default Switch;
