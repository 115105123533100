
import '../styles/textStyles.scss';
import React from "react";


const About = () => {
  return (
    <div className="flex flex-col items-center mt-20">
      <div className='max-w-screen-sm w-full'>
        <h1 >About Us</h1>
        <p>Praxilock is a homeownership intelligence platform that helps you increase flexibility, expand opportunity, and increase transparency into your home. Some of the things we offer.</p>
        <h2 className='mt-5'>Full Access to your Home Title</h2>
        <p>When was the last time you looked at your home title? Praxilock wants to give you what’s yours. You can easily access your title digitally, which will allow you to keep an eye out for any discrepancies or changes to your title.</p>
        <h2 className='mt-5'>Increase Flexibility and Opportunity</h2>
        <p>Praxilock wants to expand your options with what you can do with your home. Did you know you can access cash quickly without going through the traditional refinance process to do more with your home’s equity? You can remodel your home, pay off burdensome debts or buy more real estate.</p>
      </div>

    </div>
  );
}

export default About;
