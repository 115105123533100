import { gql } from '@apollo/client';

/*
    Queries
*/
export const GET_ALL_PARTNERS_QUERY = gql`
    query getAllPartners {
        getAllPartners{
            id
            name
            shortDescription
            logoUrl500x200
            homepageUrl
            affiliateUrl
            category
        }
    }
`;

export const GET_PARTNERS_BY_CATEGORY_QUERY = gql`
    query getPartnersByCategory($category: String!) {
        getPartnersByCategory(category: $category){
            id
            name
            shortDescription
            logoUrl500x200
            homepageUrl
            affiliateUrl
            category
        }
    }
`;

export const GET_PARTNER_BY_ID_QUERY = gql`
    query getPartnerById($id: String!) {
        getPartnerById(id: $id){
            id
            name
            shortDescription
            logoUrl500x200
            homepageUrl
            affiliateUrl
            category
        }
    }
`;

