import React from 'react'

function FeatureDescriptionTitleMonitoring() {
  return (
    <div className="flex flex-col items-center my-10 mx-5">
      <div className='max-w-screen-sm w-full text-center'>
        <h1>Title Monitoring</h1>
        <img
          alt='Title Monitoring'
          className='w-full my-5'
          src='https://storage.googleapis.com/praxi-files-frontend/content/Illustration-TitleMonitoring.svg'
        />
        <p className='mt-5'>Our home title monitoring service allows you to monitor your property's title and detect any changes or potential issues. We'll alert you if we detect any discrepancies, such as fraudulent activity or errors in the public records, so you can take action quickly to protect your home.</p>
        <p className='mt-5'>Signing up for our home title monitoring service is quick and easy. Simply visit our website and provide some basic information about your property, and we'll take care of the rest. There are no fees or hidden costs.</p>
        <p className='mt-5'>At Praxilock, we're committed to providing homeowners with the tools and information they need to protect their most valuable asset - their home. Sign up today to start enjoying the benefits of our free home title monitoring service!</p>
      </div>
    </div>
  )
}

export default FeatureDescriptionTitleMonitoring