import '../styles/components.scss'
import '../styles/widgetStyles.scss'
import React, { useEffect, useState } from "react";
import { getAuth, updatePassword, reauthenticateWithCredential, EmailAuthProvider } from "firebase/auth";
import { auth } from "../firebase";
import { onAuthStateChanged } from "firebase/auth";

import { capitalizeFirstLetter, extractNumbersFromString } from "../utils/stringTransformer";

import Switch from '../components/widgets/Switch';
import LogoutButton from '../components/auth/LogoutButton';
import { BeatLoader } from 'react-spinners';

import { GET_USER_QUERY, UPDATE_USER_MUTATION } from '../graphql/UserOperations';
import { GET_ADDRESSES_QUERY, CREATE_ADDRESS_MUTATION, DELETE_ADDRESS_MUTATION } from '../graphql/AddressOperations';
import { useQuery, useMutation } from '@apollo/client';

const ProfilePage = () => {
    const [address, setAddress] = useState(null);

    // active/inactive buttons
    const [updateContactInformationButtonActive, setUpdateContactInformationButtonActive] = useState(false);
    const [updatePasswordButtonActive, setUpdatePasswordButtonActive] = useState(false);
    const [updateAddressButtonActive, setUpdateAddressButtonActive] = useState(false);

    // loading states
    const [updateContactInformationLoading, setUpdateContactInformationLoading] = useState(false);
    const [updatePasswordLoading, setUpdatePasswordLoading] = useState(false);
    const [updateAddressLoading, setUpdateAddressLoading] = useState(false);

    // initial states
    const [initialUserDataFetched, setInitialUserDataFetched] = useState(false);
    const [initialAddressDataFetched, setInitialAddressDataFetched] = useState(false);

    // error/success messages
    const [addressErrorMessage, setAddressErrorMessage] = useState(null);
    const [changePasswordErrorMessage, setChangePasswordErrorMessage] = useState(null);
    const [changePasswordSuccessMessage, setChangePasswordSuccessMessage] = useState(null);

    // section user information input
    const [loginEmail, setLoginEmail] = useState(null);
    const [firstName, setFirstName] = useState(null); // server value
    const [lastName, setLastName] = useState(null); // server value
    const [contactEmail, setContactEmail] = useState(null); // server value
    const [phoneNumber, setPhoneNumber] = useState(null); // server value
    const [firstNameInput, setFirstNameInput] = useState(null); // input value
    const [lastNameInput, setLastNameInput] = useState(null); // input value
    const [contactEmailInput, setContactEmailInput] = useState(null); // input value
    const [phoneNumberInput, setPhoneNumberInput] = useState(null); // input value

    // section reset password
    const [currentPassword, setCurrentPassword] = useState(null);
    const [newPassword, setNewPassword] = useState(null);
    const [confirmNewPassword, setConfirmNewPassword] = useState(null);

    // section property information input
    const [currentAddressLine1, setCurrentAddressLine1] = useState(null);
    const [currentAddressLine2, setCurrentAddressLine2] = useState(null);

    const [newStreetAddress, setNewStreetAddress] = useState(null);
    const [newUnitNumber, setNewUnitNumber] = useState(null);
    const [newCity, setNewCity] = useState(null);
    const [newState, setNewState] = useState(null);

    // section title monitoring
    const [titleMonitoringEnabled, setTitleMonitoringEnabled] = useState(false);
    const [titleMonitoringEmailNotificationsEnabled, setTitleMonitoringEmailNotificationsEnabled] = useState(false);
    const [titleMonitoringPhoneNotificationsEnabled, setTitleMonitoringPhoneNotificationsEnabled] = useState(false);

    // GraphQL operations
    // Queries
    const { error: userError, data: userData } = useQuery(GET_USER_QUERY, {
        variables: { email: loginEmail },
    });
    const { error: addressesError, data: addressesData } = useQuery(GET_ADDRESSES_QUERY, {
        variables: { userLoginEmail: loginEmail },
    });
    // Mutations
    const [updateUser] = useMutation(UPDATE_USER_MUTATION);
    const [createAddress] = useMutation(CREATE_ADDRESS_MUTATION);
    const [deleteAddress] = useMutation(DELETE_ADDRESS_MUTATION);




    useEffect(() => {
        const listen = onAuthStateChanged(auth, (user) => {
            if (user) {
                setLoginEmail(user.email);
            }
        });

        if (userData && !initialUserDataFetched) {
            setInitialUserDataFetched(true);
            // Assuming your GraphQL query returns a user object
            const user = userData.getUser;
            if (user) {
                // setLoginEmail(user.loginEmail);
                setFirstName(user.firstName);
                setLastName(user.lastName);
                setContactEmail(user.contactEmail);
                setPhoneNumber(user.phone);
                setFirstNameInput(user.firstName);
                setLastNameInput(user.lastName);
                setContactEmailInput(user.contactEmail);
                setPhoneNumberInput(user.phone);

                setTitleMonitoringEnabled(user.settingsTitleMonitoringActive);
                setTitleMonitoringEmailNotificationsEnabled(user.settingsTitleMonitoringEmailNotification);
                setTitleMonitoringPhoneNotificationsEnabled(user.settingsTitleMonitoringPhoneNotification);
            }
        }

        if (addressesData && !initialAddressDataFetched) {
            setInitialAddressDataFetched(true);
            const addresses = addressesData.getAddresses;
            if (addresses && addresses.length > 0) {
                const address = addresses[0];
                const addressLine1 = capitalizeFirstLetter(address.streetAddress, { capitalizeDirections: true }) + (address.unit ? ' ' + capitalizeFirstLetter(address.unit) : '');
                const addressLine2 = capitalizeFirstLetter(address.locality) + ', ' + address.regionCode + ' ' + address.postalCode;
                setCurrentAddressLine1(addressLine1);
                setCurrentAddressLine2(addressLine2);
                setAddress(address);
            }
        }

        // activate or inactivate update contact information button
        if ((firstNameInput !== firstName ||
            lastNameInput !== lastName ||
            contactEmailInput !== contactEmail ||
            phoneNumberInput !== phoneNumber) &&
            (firstNameInput !== '' && lastNameInput !== '' && contactEmailInput !== '')) {

            setUpdateContactInformationButtonActive(!updateContactInformationLoading);
        } else {
            setUpdateContactInformationButtonActive(false);
        }

        // activate or inactivate update password button
        if (currentPassword && currentPassword !== '' &&
            newPassword && newPassword !== '' &&
            confirmNewPassword && confirmNewPassword !== '') {
            setUpdatePasswordButtonActive(!updatePasswordLoading);
        } else {
            setUpdatePasswordButtonActive(false);
        }

        // activate or inactivate update address button
        if (newStreetAddress && newStreetAddress !== '' &&
            newCity && newCity !== '' &&
            newState && newState !== '') {
            setUpdateAddressButtonActive(!updateAddressLoading);
        } else {
            setUpdateAddressButtonActive(false);
        }

        

        return () => {
            listen();
        }
    }, [initialUserDataFetched,
        initialAddressDataFetched,
        loginEmail,
        userData,
        userError,
        addressesData,
        addressesError,

        firstNameInput,
        lastNameInput,
        contactEmailInput,
        phoneNumberInput,
        firstName,
        lastName,
        contactEmail,
        phoneNumber,

        currentPassword,
        newPassword,
        confirmNewPassword,

        newStreetAddress,
        newUnitNumber,
        newCity,
        newState,
        currentAddressLine1,
        currentAddressLine2,

        updateContactInformationLoading,
        updatePasswordLoading,
        updateAddressLoading,

        address,
    ]);

    return (
        <div className="flex flex-col items-center mt-10 mb-10 pl-5 pr-5">
            {/* <UserData /> */}
            {/* <button onClick={addUser}>Test</button> */}
            <div className="max-w-screen-sm w-full">
                <h1>My account</h1>
                <p>Please be sure to keep the information updated</p>

                <div className='small-horizontal-divider mt-5 mb-5' />


                <h3>Update your contact information</h3>
                <p className='input-field-label mt-10'>Login Email (Cannot be changed):</p>
                <p>{loginEmail}</p>

                <form>
                    <p className='input-field-label mt-5'>First Name</p>
                    <input className='input-field w-full mt-2'
                        placeholder='First Name (Must not be empty)'
                        value={firstNameInput ? firstNameInput : ''}
                        onChange={(e) => {
                            setFirstNameInput(e.target.value);
                        }}
                    />

                    <p className='input-field-label mt-5'>Last Name</p>
                    <input className='input-field w-full mt-2'
                        placeholder='Last Name (Must not be empty'
                        value={lastNameInput ? lastNameInput : ''}
                        onChange={(e) => {
                            setLastNameInput(e.target.value);
                        }}
                    />

                    <p className='input-field-label mt-5'>Contact Email</p>
                    <input className='input-field w-full mt-2'
                        placeholder='Email Address (Must not be empty)'
                        value={contactEmailInput ? contactEmailInput : ''}

                        onChange={(e) => {
                            setContactEmailInput(e.target.value);
                        }}
                    />

                    <p className='input-field-label mt-5'>Phone Number</p>
                    <input className='input-field w-full mt-2'
                        placeholder='Phone Number'
                        value={phoneNumberInput ? phoneNumberInput : ''}

                        onChange={(e) => {
                            setPhoneNumberInput(e.target.value);
                        }}
                    />

                    <button className={
                        updateContactInformationButtonActive ?
                            'filled-button w-full mt-5' :
                            'inactive-filled-button w-full mt-5'
                    }
                        onClick={async (e) => {
                            e.preventDefault();
                            // avoid multiple clicks
                            if (!updateContactInformationLoading) {
                                setUpdateContactInformationLoading(true);
                                const res = await updateUser({
                                    variables:
                                    {
                                        loginEmail: loginEmail,
                                        firstName: firstNameInput.trim(),
                                        lastName: lastNameInput.trim(),
                                        contactEmail: contactEmailInput.trim(),
                                        phone: phoneNumberInput.trim()
                                    }
                                });
                                if (res && res.data && res.data.updateUser) {
                                    setFirstNameInput(res.data.updateUser.firstName);
                                    setLastNameInput(res.data.updateUser.lastName);
                                    setContactEmailInput(res.data.updateUser.contactEmail);
                                    setPhoneNumberInput(res.data.updateUser.phone);
                                    setFirstName(res.data.updateUser.firstName);
                                    setLastName(res.data.updateUser.lastName);
                                    setContactEmail(res.data.updateUser.contactEmail);
                                    setPhoneNumber(res.data.updateUser.phone);
                                } else {
                                    setFirstNameInput(firstName);
                                    setLastNameInput(lastName);
                                    setContactEmailInput(contactEmail);
                                    setPhoneNumberInput(phoneNumber);
                                }
                                setUpdateContactInformationLoading(false);
                            }
                        }}>
                        {updateContactInformationLoading ? <BeatLoader color="#ccc" loading={true} /> : 'Update Contact Information'}
                    </button>
                </form>
                <div className='thick-horizontal-divider mt-5 mb-5' />


                <h3>Change Password</h3>
                <form>
                    <p className='input-field-label mt-5'>Current Password</p>
                    <input className='input-field w-full mt-2'
                        placeholder='Password'
                        type='password'
                        onChange={(e) => {
                            setCurrentPassword(e.target.value);
                        }}
                    />

                    <p className='input-field-label mt-5'>New Password</p>
                    <input className='input-field w-full mt-2'
                        placeholder='Password'
                        type='password'
                        onChange={(e) => {
                            setNewPassword(e.target.value);
                        }}
                    />

                    <p className='input-field-label mt-5'>Confirm New Password</p>
                    <input className='input-field w-full mt-2'
                        placeholder='Password'
                        type='password'
                        onChange={(e) => {
                            setConfirmNewPassword(e.target.value);
                        }}
                    />
                    <p className="text-green-500">{changePasswordSuccessMessage ? changePasswordSuccessMessage : ""}</p>
                    <p className="text-red-500">{changePasswordErrorMessage ? changePasswordErrorMessage : ""}</p>

                    <button className={
                        updatePasswordButtonActive ?
                            'filled-button w-full mt-5' :
                            'inactive-filled-button w-full mt-5'}
                        onClick={async (e) => {
                            e.preventDefault();
                            if (!updatePasswordLoading) {
                                setUpdatePasswordLoading(true);
                                setChangePasswordErrorMessage(null);
                                setChangePasswordSuccessMessage(null);
                                const auth = getAuth();
                                const user = auth.currentUser;

                                const credential = EmailAuthProvider.credential(loginEmail, currentPassword);

                                reauthenticateWithCredential(user, credential).then(() => {
                                    if (newPassword !== confirmNewPassword) {
                                        setChangePasswordErrorMessage('The passwords do not match');
                                    } else {
                                        updatePassword(user, newPassword).then(() => {
                                            // Update successful.
                                            setChangePasswordSuccessMessage('Successfully changed password');
                                            setCurrentPassword('');
                                            setNewPassword('');
                                            setConfirmNewPassword('');
                                        }).catch((error) => {
                                            // Check the error code for specific cases - updatePassword
                                            if (error.code === 'auth/weak-password') {
                                                setChangePasswordErrorMessage('Weak password. Password should be at least 6 characters long.');
                                            } else {
                                                setChangePasswordErrorMessage('An unknown error occurred');
                                            }
                                        });
                                    }


                                }).catch((error) => {
                                    // Check the error code for specific cases - reauthenticateWithCredential
                                    if (error.code === 'auth/user-not-found') {
                                        setChangePasswordErrorMessage('User not found');
                                    } else if (error.code === 'auth/wrong-password') {
                                        setChangePasswordErrorMessage('Wrong current password');
                                    } else {
                                        setChangePasswordErrorMessage('An unknown error occurred');
                                    }
                                }).finally(() => {
                                    setUpdatePasswordLoading(false);
                                });
                            }

                        }}>
                        {updatePasswordLoading ? <BeatLoader color="#ccc" loading={true} /> : 'Change Password'}
                    </button>
                </form>
                <div className='thick-horizontal-divider mt-5 mb-5' />


                <h3>Property Information</h3>
                <p className='body-text mt-5'>
                    We use this information to support you with individual home intelligence.
                </p>
                {address ?
                    <div>
                        <p className='input-field-label mt-5'>Your Address</p>
                        <p className='body-text'>{currentAddressLine1}</p>
                        <p className='body-text'>{currentAddressLine2}</p>

                        <p className='input-field-label mt-5'>Update Address</p>
                        <p className='body-text mt-2'>
                            If you have moved, please update your address below.
                        </p>
                    </div> :
                    <div>
                        <p className='input-field-label mt-5'>Please share your address</p>
                        <p className='body-text'>Sharing your address with us will enable us to offer you a seamless and personalized service experience.</p>
                    </div>
                }

                <form>
                    <p className='input-field-label mt-5'>Address</p>
                    <input className='input-field w-full mt-2'
                        placeholder='New street address'
                        value={newStreetAddress ? newStreetAddress : ''}
                        onChange={(e) => {
                            setNewStreetAddress(e.target.value);
                        }}

                    />
                    <input className='input-field w-full mt-2'
                        placeholder='Unit Number (optional)'
                        value={newUnitNumber ? newUnitNumber : ''}
                        onChange={(e) => {
                            setNewUnitNumber(e.target.value);
                        }}
                    />
                    <input className='input-field w-full mt-2'
                        placeholder='City'
                        value={newCity ? newCity : ''}
                        onChange={(e) => {
                            setNewCity(e.target.value);
                        }}
                    />
                    <input className='input-field w-full mt-2'
                        placeholder='State'
                        value={newState ? newState : ''}
                        onChange={(e) => {
                            setNewState(e.target.value);
                        }}
                    />
                    <p className="text-red-500">{addressErrorMessage ? addressErrorMessage : ""}</p>
                    <button className={updateAddressButtonActive ?
                        'filled-button w-full mt-5' :
                        'inactive-filled-button w-full mt-5'
                    }
                        onClick={async (e) => {
                            // if there is already an address, delete it and then create a new one
                            // this is to trigger backend logic to fetch data around the new address
                            e.preventDefault();
                            if (!updateAddressLoading) {
                                setUpdateAddressLoading(true);
                                setAddressErrorMessage(null);

                                try {
                                    var currAddressId = address ? address.id : null;

                                    // if (address) {
                                    //     console.log('deleting address');
                                    //     deleteAddress({
                                    //         variables: { id: address.id }
                                    //     });
                                    //     setCurrentAddressLine1(null);
                                    //     setCurrentAddressLine2(null);
                                    // }
                                    const res = await createAddress({
                                        variables:
                                        {
                                            userLoginEmail: loginEmail,
                                            street: newStreetAddress.trim(),
                                            city: newCity.trim(),
                                            state: newState.trim(),
                                            unit: newUnitNumber ? extractNumbersFromString(newUnitNumber).trim() : null,
                                        }
                                    });

                                    if (res && res.data && res.data.createAddressByFields) {
                                        const result = res.data.createAddressByFields;
                                        const addressLine1 = result.streetAddress + (result.unit ? ' ' + result.unit : '');
                                        const addressLine2 = result.locality + ', ' + result.regionCode + ' ' + result.postalCode;
                                        
                                        // if a new address was created, delete the old one from the database
                                        if (currAddressId && res.data.createAddressByFields.id) {
                                            console.log('deleting address', currAddressId)
                                            deleteAddress({
                                                variables: { id: currAddressId }
                                            });
                                        }
                                        
                                        setCurrentAddressLine1(addressLine1);
                                        setCurrentAddressLine2(addressLine2);
                                        setNewStreetAddress('');
                                        setNewCity('');
                                        setNewState('');
                                        setNewUnitNumber('');
                                        setUpdateAddressButtonActive(false);
                                        setAddress(res.data.createAddressByFields)


                                        
                                        
                                    }
                                } catch (error) {
                                    setAddressErrorMessage('We could not update your address with the given parameters.');
                                    console.error(error);
                                } finally {
                                    setUpdateAddressLoading(false);
                                }
                            }
                        }}
                    >
                        {updateAddressLoading ? <BeatLoader color="#ccc" loading={true} /> : 'Update Property Information'}
                    </button>
                </form>

                <div className='thick-horizontal-divider mt-5 mb-5' />

                <h3>Title Monitoring</h3>
                <p className='input-field-label mt-5'>Enable Free Title Monitoring</p>
                <p className='body-text mt-2'>
                    Our free title monitoring service will alert you if any change is made to your property title.
                    It is also featured at your dashboard when it is enabled.
                </p>
                <div className='medium-horizontal-divider mt-5 mb-5' />

                <div className='flex flex-row justify-between items-center mt-5'>
                    <p>Enable Title Monitoring on the property address that I have entered in the account.</p>
                    <Switch
                        initialValue={titleMonitoringEnabled}
                        onChange={async (value) => {
                            setTitleMonitoringEnabled(value);
                            const res = await updateUser({
                                variables:
                                {
                                    loginEmail: loginEmail,
                                    settingsTitleMonitoringActive: value
                                }
                            });

                            if (!res || !res.data || !res.data.updateUser || res.data.updateUser.settingsTitleMonitoringActive !== value) {
                                setTitleMonitoringEnabled(value);
                            }
                        }} />
                </div>

                <div className='flex flex-row justify-between items-center mt-5'>
                    <p>Send notifications to my email address when a change is detected.</p>
                    <Switch
                        initialValue={titleMonitoringEmailNotificationsEnabled}
                        onChange={async (value) => {
                            setTitleMonitoringEmailNotificationsEnabled(value);
                            const res = await updateUser({
                                variables:
                                {
                                    loginEmail: loginEmail,
                                    settingsTitleMonitoringEmailNotification: value
                                }
                            });
                            if (!res || !res.data || !res.data.updateUser || res.data.updateUser.settingsTitleMonitoringEmailNotification !== value) {
                                setTitleMonitoringEmailNotificationsEnabled(!value);
                            }

                        }} />
                </div>

                <div className='flex flex-row justify-between items-center mt-5'>
                    <p>Send notifications to my phone when a change is detected.</p>
                    <Switch
                        initialValue={titleMonitoringPhoneNotificationsEnabled}
                        onChange={async (value) => {
                            setTitleMonitoringPhoneNotificationsEnabled(value);
                            const res = await updateUser({
                                variables:
                                {
                                    loginEmail: loginEmail,
                                    settingsTitleMonitoringPhoneNotification: value
                                }
                            });
                            if (!res || !res.data || !res.data.updateUser || res.data.updateUser.settingsTitleMonitoringPhoneNotification !== value) {
                                setTitleMonitoringPhoneNotificationsEnabled(!value);
                            }

                        }} />
                </div>


                <div className='thick-horizontal-divider mt-5 mb-5' />

                <LogoutButton className='w-full' />
            </div>
        </div>

    )
}

export default ProfilePage;