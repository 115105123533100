import React from "react";
import { useMediaQuery } from "react-responsive";
import '../../styles/components.scss';
import '../../styles/widgetStyles.scss';

import PartnerQuickListItem from "./PartnerQuickListItem";

const PartnerQuickList = ({ partners }) => {
    const isMobile = useMediaQuery({ maxWidth: 768 });

    return (
        <div className={isMobile ? 
            'grid grid-cols-1 gap-10 w-full':
            "grid grid-cols-2 gap-10 w-full"}>
            {partners.map((partner, index) => {
                return (
                    <PartnerQuickListItem
                        key={index}
                        logoSrc={partner.logoSrc}
                        title={partner.title}
                        shortDescription={partner.shortDescription}
                        url={partner.url}
                    />
                )
            })
            }
        </div>
    )
}

export default PartnerQuickList;
