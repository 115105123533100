// a function that takes a string and returns a new string with the first letter of each word capitalized
// example: 255 S RENGSTORFF AVE APT 34
// result: 255 S Rengstorff Ave Apt 34
// certain that are given as parameter substrings should be all caps
const DIRECTIONS = ['N', 'S', 'E', 'W', 'NE', 'NW', 'SE', 'SW'];

function capitalizeFirstLetter(word, { ignore = [], capitalizeDirections = false } = {}) {
    if (typeof word !== 'string') {
        return word;
    }
    const words = word.split(' ');
    const capitalizedWords = words.map((word) => {
        if (ignore.includes(word)) {
            return word;
        }
        if (capitalizeDirections && DIRECTIONS.includes(word.toUpperCase())) {
            return word.toUpperCase();
        }
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });
    return capitalizedWords.join(' ');
}

// this function removes each characters in a string that is not a number
// example: APT 34 -> 34
function removeNonNumericCharacters(string) {
    return string.replace(/\D/g, '');
}

// this function takes an object and returns a new object with the same keys but with the values capitalized using the capitalizeFirstLetter function
function capitalizeObjectValues(object, { ignore = [], capitalizeDirections = false } = {}) {
    const capitalizedObject = {};
    Object.keys(object).forEach((key) => {
        capitalizedObject[key] = capitalizeFirstLetter(object[key], { ignore, capitalizeDirections });
    });
    return capitalizedObject;
}

// this function takes a string consisting of numbers and returs a new string with the numbers formatted as a dollar amount without cents
// example: 1234 -> $1,234
function formatDollarAmount(string) {
    const number = Number(string);
    if (Number.isNaN(number)) {
        return string;
    }
    return number.toLocaleString('en-US', { style: 'currency', currency: 'USD' }).split('.')[0];
}

// this function taks a large number and returns a string with dollar amount and B, M or K appended without cents
// example: 1234567 -> $1.23M
// example 2: 123456 -> $123K
function formatLargeDollarAmount(largeNumber) {
    const number = Number(largeNumber);
    if (Number.isNaN(number)) {
        return largeNumber;
    }
    if (largeNumber >= 1000000000) {
        return `$${(largeNumber / 1000000000).toFixed(2).split('.')[0]} B`;
    }
    if (largeNumber >= 1000000) {
        return `$${(largeNumber / 1000000).toFixed(2).split('.')[0]} M`;
    }
    if (largeNumber >= 1000) {
        return `$${(largeNumber / 1000).toFixed(2).split('.')[0]} K`;
    }
    return `$${largeNumber}`;
}

function extractNumbersFromString(inputString) {
    // Check if the inputString is null or undefined
    if (inputString === null || inputString === undefined) {
        // Handle the case where inputString is null or undefined
        return '';
    }
    // Use a regular expression to match and extract numbers
    const numbersArray = inputString.match(/\d+/g);

    // If there are numbers, join them into a single string
    if (numbersArray) {
        return numbersArray.join('');
    } else {
        // If no numbers are found, return an empty string or handle it as needed
        return '';
    }
}

module.exports = {
    capitalizeFirstLetter,
    capitalizeObjectValues,
    removeNonNumericCharacters,
    formatDollarAmount,
    formatLargeDollarAmount,
    extractNumbersFromString,
};