import "../../styles/widgetStyles.scss"

import { signOut } from "firebase/auth";
import React from "react";
import { auth } from "../../firebase";
import { useNavigate } from 'react-router-dom'


const LogoutButton = ({ className }) => {
    const navigate = useNavigate();
    const userSignOut = () => {
        signOut(auth).then(() => {

            navigate("/");

        }).catch((error) => {
            // An error happened.
            console.log(error);
        });
    }
    return (
        <button className={`${className} outline-button`} onClick={userSignOut}>Logout</button>

    )
}

export default LogoutButton