import '../../styles/widgetStyles.scss'

import React, { useState } from 'react';

const TabMenu = ({ content, className }) => {
    const [activeTab, setActiveTab] = useState(Object.keys(content)[0]);

    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
    };

    return (
        <div className={className}>
            <div className="tab-menu">
                {Object.keys(content).map((tab) => (
                    <div
                        key={tab}
                        className={`tab ${activeTab === tab ? 'active-tab active-tab-title' : 'tab-title'}`}
                        onClick={() => handleTabClick(tab)}
                    >
                        {tab}
                    </div>
                ))}
            </div>
            <div className="tab-content">
                {content[activeTab]}
            </div>
        </div>
    );
};

export default TabMenu;
