import React, { useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { auth } from "../firebase";
import { onAuthStateChanged } from "firebase/auth";
import SignUpForm from '../components/auth/SignUpForm';
import SignUpPromo from '../components/widgets/SignUpPromo';
import { useNavigate } from 'react-router-dom'

const SignUpPage = () => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const navigate = useNavigate();

  useEffect(() => {
    const listen = onAuthStateChanged(auth, (user) => {
      if (user) {
        // navigateToDashboard();
        navigate("/dashboard");
      } else {
      }
    });
    return () => {
      listen();
    }
  }, [navigate]);


  const navigateToSignIn = () => {
    navigate("/signin");
  }

  // eslint-disable-next-line
  return (
    <div className='flex flex-col items-center'>
      <div className={isMobile 
        ? 'flex flex-col' 
        :'grid grid-cols-2 gap-10 pb-10 pt-10 max-w-screen-lg'}>
        <div className='p-5'>
          <h1>Create your account</h1>
          <SignUpForm />
          <div className='flex flex-col items-center'>
            <button className="text-button mt-5" onClick={navigateToSignIn}>Login instead</button>
            <div>
              <span className="create-account-agree-legals">
                By creating an account, you agree and accept to Praxilock's
              </span>
              <a className="create-account-agree-legals-link" href={process.env.REACT_APP_LINK_TERMS_OF_SERVICE}> Terms of Service</a>
              <span className="create-account-agree-legals"> and our </span>
              <a className="create-account-agree-legals-link" href={process.env.REACT_APP_LINK_PRIVACY_STATEMENT}>Privacy Statement</a>
            </div>
            <p className="create-account-agree-legals"> </p>
          </div>
        </div>
        <div className='flex flex-col px-5'>

          <SignUpPromo />
        </div>
      </div>
    </div>

  )


}

export default SignUpPage;
