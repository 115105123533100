import React from 'react'

function Terms() {
    return (
        <div className="flex flex-col items-center my-20">
            <div className='max-w-screen-sm w-full'>
                <h1>Praxilock Privacy & Security Policy</h1>

                <h2>Overview</h2>
                <p>
                    Praxilock Corporation d/b/a Praxilock (“Praxilock” or “we” or “us” or “our”) understands the importance
                    of privacy to its customers, and we are committed to protecting and safeguarding personal information
                    that you may provide to us.
                </p>
                <p>
                    This Privacy Policy is intended to help you understand what types of personal information we collect,
                    how we collect your personal information, how we use your personal information, with whom your personal
                    information may be shared, how we protect and how long we store your personal information, and what
                    choices you have about how that information is collected and used. This Privacy Policy applies to
                    any person visiting or using our website at www.praxilock.com or any other websites we may operate
                    from time to time (collectively “website”). The Privacy Policy applies to any and all personal information
                    provided to us by consumers who contact us about our services, and to any personal information provided
                    to us by customers who receive or use our services.
                </p>
                <p>
                    By accessing and/or using our services and/or website, you agree that your use of our services and any dispute
                    over privacy is subject to this Privacy Policy. If you do not agree with this Privacy Policy, please discontinue
                    the use of our services and website.
                </p>

                <h2>Types of Personal Information We Collect</h2>
                <ol>
                    <li>Information you may provide about yourself, such as your first and last name, phone number, email address,
                        mailing address, and real property address to monitor.
                    </li>
                    <li>
                        Financial information when you purchase our services, such as your credit/debit card number, expiration
                        date, security code and billing zip code associated with your card.
                    </li>
                    <li>
                        Certain demographic information such as age, gender, and income level.
                    </li>
                    <li>
                        Information about your unique browser, device identifier, or Internet Protocol (IP) address, as well as
                        your modem speed and type of operating system used, and pages viewed when you visit and browse our website.
                    </li>
                    <li>
                        Cookies are bits of electronic information that can be transferred to your computer or other electronic
                        device to uniquely identify your browser. When you use our services, we may place one or more cookies on
                        your computer or other electronic device. You can adjust settings on your browser at any time to refuse
                        cookies. However, if you choose to disable cookies, some of the features of our website and/or services
                        may not operate properly.
                    </li>
                </ol>

                <h2>How We Collect Personal Information</h2>
                <p>We collect your personal information in different ways when you access various parts of our website and services.
                    We collect information from you in the following ways:</p>
                <ol>
                    <li>When you voluntarily provide it to us when you elect to send us a message using our website.</li>
                    <li>When you sign up to use our services.</li>
                    <li>When you call our call center and provide information to us.</li>
                    <li>Our website site server automatically records visitor information based on your activity on our website.</li>
                    <li>When you participate in our surveys, usability studies, contests, and other programs.</li>
                    <li>When you use our services, your computer or other electronic device communicates a variety of information to
                        computer servers, including the existence of cookies, your IP address, and information about your browser program.</li>
                </ol>

                <h2>How We Use Personal Information</h2>
                <p>We generally use the information collected from you to create your account and provide you with the services you
                    purchase from us. We additionally use the information collected from you to communicate with you and respond to
                    your questions and to operate our website. Once you provide us with contact information through our website, we
                    may send you a confirmation e-mail to let you know that your information was received successfully. We may use
                    this information to respond to your requests and present you with advertising that we believe will be of interest
                    or useful to you. We can correspond with you by electronic mail or by telephone, depending upon your preference as
                    stated to us. We may use your e-mail address to enhance our customer service experience in using our services,
                    and to notify you if our website is unavailable. If you do not wish to receive e-mails from us, please contact us
                    to have your e-mail account removed from your records.</p>
                <p>
                    We may periodically conduct surveys, usability studies, contests and other programs that help us improve and promote
                    our services. We do not share information you provide when participating in our voluntary programs with any sponsor
                    or third party without your consent.
                </p>
                <p>
                    We may use information about user behavior, user experience, user equipment, computing and Internet environment to
                    improve the performance of our website.
                </p>
                <p>
                    If you communicate directly with us by email, phone, or letter, we may collect and store a record of your communication.
                    We may use the information you provide during your communication with us to respond to your issues and inquiries. Calls
                    to our call center may be recorded or monitored for quality assurance and customer service purposes.
                </p>

                <h2>With Whom We May Share Personal Information</h2>
                <p>
                    We generally do not share, rent, lease or sell your personal information to third parties. However, we may share your information:
                </p>
                <ol>
                    <li>With our affiliates who sell our services to enable them to contact you regarding our services.</li>
                    <li>When you consent or direct us to do so.</li>
                    <li>With our employees and other service providers as necessary to perform our services to you.</li>
                    <li>If required to do so by law or if we have a good-faith belief that such action is reasonably necessary to
                        <ol type="a">
                            <li>Comply with legal process.</li>
                            <li>Respond to any claims against us or protect our rights or property.</li>
                            <li>Protect the rights, property, or personal safety of our employees, customers, or the public. This includes exchanging information with other companies and organizations for fraud protection and credit risk reduction.</li>
                        </ol>
                    </li>
                    <li>To a successor entity if we become subject to a corporate merger, corporate acquisition, or similar transaction involving the sale or combination of substantially all of our assets.</li>
                </ol>

                <h2>How We Protect Personal Information</h2>
                <p>The security of your personal information is important to us but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. We take precautions to ensure that our visitors’ personal information is secured, and we strive to use commercially acceptable means to protect your personal information, but we cannot guarantee its absolute security. We use a variety of industry-standard technical, contractual, administrative and physical security measures and procedures to help protect your personal information from unauthorized access, to maintain data accuracy and to enable the correct use of information. Unless otherwise provided, we restrict access to personal information to those employees who need access to perform their job functions. Please note that despite our best efforts, no one can guarantee the security of personal information. Unauthorized entry or use, hardware or software failure, and other factors may compromise the security of personal information at any time.</p>

                <h2>How Long We Retain Personal Information</h2>
                <p>We will retain your personal information for as long as you have an active account, as needed to provide you with our services, to comply with our legal, financial reporting, or compliance obligations, and to enforce this Privacy Policy.</p>

                <h2>Your Personal Information Protection Rights and Choices</h2>
                <p>
                    You have the following rights in relation to our processing of your personal information. Please note that these rights are not absolute, and we may refuse requests where exceptions apply.
                </p>
                <p>
                    You have the right to:
                </p>
                <ul>
                    <li>To obtain access to, and copies of, the personal information that we hold about you;</li>
                    <li>To require us to correct the personal information we hold about you if it is incorrect;</li>
                    <li>To require us to delete or restrict your personal information in certain circumstances (e.g., if we are using your personal information on the basis of your consent and have no other legal basis to use such, you may request your personal information be deleted when you withdraw your consent);</li>
                    <li>To require us to restrict our information processing activities in certain circumstances;</li>
                    <li>Select whether we share your personal information with third parties by or change your selection by e-mailing us at <a href="mailto:contact@Praxilock.com">contact@praxilock.com</a> and placing “share” or “do-not-share” in the subject line (please include your full name);</li>
                    <li>To object to any of our particular processing activities of your personal information;</li>
                    <li>To receive from us the personal information we hold about you, in a reasonable format specified by you, including for the purpose of you transmitting that personal information to another data controller;</li>
                    <li>Where our processing is based on your consent, you may withdraw that consent, without affecting the lawfulness of our processing based on consent before its withdrawal;</li>
                    <li>If you are not satisfied with how we are processing your personal information, you can make a complaint to a data protection supervisory authority in the US or EU, as applicable, including the data protection regulator in the EU country where you are located; and</li>
                    <li>To request that we restrict the processing of your personal information pending our investigation and/or verification of your claim, if you believe your personal information is inaccurate, no longer necessary for our business purposes, or if you object to our processing of your personal information.</li>
                </ul>
                <p>
                    If you wish to exercise any of the rights described above, or submit a complaint on how we have handled your personal information, please contact us by e-mail at contact@praxilock.com or by telephone at 310-948-6048
                </p>
                <ul>
                    <li>You may manage your registration information at any time by visiting and logging into our website using your account log in information;</li>
                    <li>You may opt-out of receiving e-mails or newsletters and other offers by clicking on the unsubscribe link at the bottom of any of our e-mails or newsletters; and</li>
                    <li>You may be able to configure your browser to reject cookies, but doing so will limit the functionality and negatively impact your experience using our services.</li>
                </ul>

                <h2>California Residents Privacy Rights</h2>
                <p>Under California Civil Code sections 1798.83-1798.84, California residents are entitled to ask certain businesses to respond to requests about the business practices related to disclosing personal information to third parties for the third parties’ direct marketing purposes. Alternatively, businesses may have in place a policy not to disclose personal information of customers to third parties for the third parties' direct marketing purposes if the customer has exercised an option to opt out of such information-sharing. We require you to expressly opt-in to any disclosure of your personal information to third parties for the third parties' direct marketing purposes, which is stricter than required under California law. If you are a California resident and would like a copy of this Privacy Policy, please submit a written request to the address below.</p>
                <p>Visiting Our Websites or Using Our Services from Outside the United States</p>
                <p>If you are visiting our websites or using our services from outside the United States, please be aware that your personal information will typically be transferred to, stored, and processed in the United States where our servers are located, and our central database is operated. The information protection and other laws of the United States and other countries might not be as comprehensive as those in your country. Please be assured that we seek to take reasonable steps to ensure that your personal information is protected. If you are located outside United States and choose to provide information to us, please note that we transfer the information, including personal information, to United States and process it there. Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.</p>

                <h2>Users of Our Websites or Services from the European Union (“EU”)</h2>
                <p>If your personal information is subject to the General Data Protection Regulation (“GDPR”), in the course of providing our websites or our services to you, we may transfer your personal information outside of the EU, principally to our servers in the United States; this means that your personal information will not have the automatic protection of European information protection laws (including the GDPR) which apply in the EU. In these circumstances, in order to ensure that your personal information continues to have adequate protection when it is transferred outside the EU, your personal information will only be transferred on one of the following bases:</p>
                <ul>
                    <li>Where the transfer is subject to one or more of the appropriate safeguards for international transfers prescribed by applicable law (for example, standard information protection clauses adopted by the European Commission);</li>
                    <li>A European Commission decision provides that the country or territory to which the transfer is made ensures an adequate level of protection; or</li>
                    <li>There exists another situation where the transfer is permitted under applicable law (for example, where we have your explicit consent).</li>
                </ul>

                <p>You can obtain more details of the protection given to your personal information when it is transferred outside the EU by contacting us using the details set out below.</p>

                <h2>Children Under The Age 13</h2>
                <p>We do not knowingly collect information from individuals under 13 years of age. Our services are not designed to attract the attention of persons under the age of 13. If you are under thirteen, you should not use or provide any personal information on the website. We will not knowingly disclose any information about children under thirteen to any third party. If we learn that personal information of children under 13 years of age has been collected on or through our services, we will take the appropriate steps to delete such personal information. If you are the parent or legal guardian of a child under 13 years of age who has used our services, please contact us by email at contact@praxilock.com to have your child’s transactions terminated and personal information deleted. </p>

                <h2>Third Party Websites</h2>
                <p>Our website may contain links to third party websites. These third-party websites may collect personal information about users on those websites, and this Privacy Policy does not extend to these external websites and third parties. We are not responsible for the privacy practices or the content of any third-party websites. We encourage you to review the privacy policies posted on any such third-party sites.</p>

                <h2>Contacting Us</h2>
                <p>If you have any questions about this Privacy Policy or our privacy practices, or to instruct us regarding your choices regarding your personal information, you may contact us using any of the following ways:</p>
                <p>By email: contact@praxilock.com</p>
                <p>By telephone: 310-948-6048</p>
                <p>We will attend to your questions, concerns, or instructions promptly.</p>
                <p>Please note that this Privacy Policy may be amended from time to time. Please check our website frequently to see recent changes, additions and deletions to this Privacy Policy. By using our services, you agree to the terms of this Privacy Policy.</p>
            </div>

        </div>
    )
}

export default Terms