import '../styles/styles.scss';
import '../styles/textStyles.scss';
import '../styles/widgetStyles.scss';
import '../styles/landingpage.scss';
import React, { useEffect } from "react";

// import { auth } from "../firebase";
// import { onAuthStateChanged } from "firebase/auth";
import { useNavigate } from 'react-router-dom'

import { useMediaQuery } from "react-responsive";

function LandingPage() {
    const isMobile = useMediaQuery({ maxWidth: 768 });

    const navigate = useNavigate();

    useEffect(() => {

        // const listen = onAuthStateChanged(auth, (user) => {
        //     if (user) {
        //         navigate("/dashboard");
        //     }
        // });

        // return () => {

        //     listen();
        // }
    }, [navigate]);
    return (
        <div>
            <div className='relative'>
                <div className={isMobile ?
                    'my-5 mx-5 -z-10' :
                    'absolute inset-0 flex items-center ml-10 max-w-xl'}>
                    <h1 className={isMobile ? '' : 'text-white'}>Unlock your property's true potential</h1>
                </div>
                {!isMobile &&
                    <img
                        className='w-full'
                        src={isMobile ? 'https://storage.googleapis.com/praxi-files-frontend/content/Photo-Mobile-AboutBanner.jpg' :
                            'https://storage.googleapis.com/praxi-files-frontend/content/Photo-AboutBanner.jpg'}
                        alt='Banner'
                    />}
            </div>


            {/* <div className={isMobile ?
                'bg-green-300 py-10 px-10' :
                'bg-green-300 py-20 px-10 '}>
                <div className={isMobile ?
                    '' :
                    'max-w-2xl'}>
                    <h2>Your financial future is our priority</h2>
                    <p className='body-text mt-5'>Understanding how your home can work for you shouldn't be difficult. That's why we believe in a world where everyone should be able to have all the financial tools at their fingertips. Our mission is to empower homeowners everywhere to take control of their financial future.</p>
                </div>
            </div> */}

            {/* <div className={isMobile ?
                'bg-white py-10' :
                'bg-white py-10 px-10'}>
                <h2 className='text-center'>We provide tools to empower your home ownership journey</h2>
                <div className={isMobile ?
                    'grid grid-cols-2 gap-y-8 mt-5' :
                    'grid grid-cols-4 gap-y-8 mt-5 '}>
                    <div className='flex justify-center'>
                    <div className='flex flex-col items-center'>
                        <img
                            className='w-32'
                            src='https://storage.googleapis.com/praxi-files-frontend/content/Icon-Credit.svg'
                            alt='Credit Icon'
                        />
                        <h3>Credit</h3>
                    </div>
                    <div className='flex flex-col items-center'>
                        <img
                            className='w-32'
                            src='https://storage.googleapis.com/praxi-files-frontend/content/Icon-Cards.svg'
                            alt='Cards Icon'
                        />
                        <h3>Cards</h3>
                    </div>
                    <div className='flex flex-col items-center mr-20'>
                        <img
                            className='w-32'
                            src='https://storage.googleapis.com/praxi-files-frontend/content/Icon-Equity.svg'
                            alt='Equity Icon'
                        />
                        <h3>Equity</h3>
                    </div>
                    <div className='flex flex-col items-center'>
                        <img
                            className='w-32'
                            src='https://storage.googleapis.com/praxi-files-frontend/content/Icon-Construction.svg'
                            alt='Construction Icon'
                        />
                        <h3>Construction</h3>
                    </div>
                    <div className='flex flex-col items-center'>
                        <img
                            className='w-32'
                            src='https://storage.googleapis.com/praxi-files-frontend/content/Icon-Loans.svg'
                            alt='Loans Icon'
                        />
                        <h3>Loans</h3>
                    </div>
                    <div className='flex flex-col items-center'>
                        <img
                            className='w-32'
                            src='https://storage.googleapis.com/praxi-files-frontend/content/Icon-Money.svg'
                            alt='Money Icon'
                        />
                        <h3>Money</h3>
                    </div>
                    <div className='flex flex-col items-center'>
                        <img
                            className='w-32'
                            src='https://storage.googleapis.com/praxi-files-frontend/content/Icon-Renovation.svg'
                            alt='Renovation Icon'
                        />
                        <h3>Renovation</h3>
                    </div>
                    <div className='flex flex-col items-center'>
                        <img
                            className='w-32'
                            src='https://storage.googleapis.com/praxi-files-frontend/content/Icon-Upgrades.svg'
                            alt='Upgrades Icon'
                        />
                        <h3>Upgrades</h3>
                    </div>
                </div>
            </div> */}

            <div className='w-full bg-cyan-300 py-10 px-10'>
                <div className={isMobile ?

                    'flex flex-col' :
                    'grid grid-cols-3 items-center justify-items-center'}>
                    <div className='col-span-2'>
                        <h2>We're free and always will be.</h2>
                        <p className='body-text mt-5'>We believe in keeping an eye on your own property and protecting yourself from others who want to steal what you worked hard for. Everyone deserves the peace of mind knowing what belongs to them is safe and secure.</p>
                        <p className='body-text mt-5'>Beyond secure monitoring, becoming a member means that we will provide personalized offers and recommendations tailored towards your needs. It's in our best interest to provide you with what you're looking for because we make money if you choose to purchase offers with partners through our platform. When you win, we win. We're all in this together.</p>
                    </div>
                    <img
                        className={isMobile ?
                            'w-full mt-5' :
                            'max-w-96'}
                        src='https://storage.googleapis.com/praxi-files-frontend/content/Illustration-AboutFree.svg'
                        alt='About Free Illustration'
                    />
                </div>
            </div>

            <div className={isMobile ?
                'bg-white py-10 px-10 flex flex-col items-center' :
                'bg-white py-10 px-10 grid grid-cols-4 items-center justify-items-center'}>
                <img

                    src='https://storage.googleapis.com/praxi-files-frontend/content/Illustration-GetInTouch.svg'
                    alt='Get In Touch Illustration'
                />
                <div className={isMobile ?
                    'mt-5' :
                    'col-span-3'}>
                    <h2>Get in touch.</h2>
                    <p className='body-text mt-5'>We would love to hear from your!</p>
                    <p className='body-text mt-5'>Let us know how we can better help you with your needs or just let us know how we're doing. Your can reach us at <a className="alternate-text-link" href="mailto:contact@praxilock.com">contact@praxilock.com</a>.</p>
                </div>
            </div>
        </div>
    )
}

// const HomeBanner = ({ header, preheader, text }) => {
//     const isMobile = useMediaQuery({ maxWidth: 768 });
//     return (
//         <div className='homebanner'>
//             <div className={isMobile
//                 ? 'flex flex-col w-full'
//                 : 'grid grid-cols-5 grid-rows1'}>

//                 <div className={isMobile ?
//                     'p-5 col-span-3' :
//                     'p-5 col-span-3 flex flex-col justify-center'}>
//                     <h5>{preheader}</h5>
//                     <h1>{header}</h1>
//                     <p className="body-text mt-5 mb-7">{text}</p>
//                     <div className={isMobile
//                         ? 'flex flex-row w-full'
//                         : 'flex flex-row'}>
//                         <SignUpRouteButton className={isMobile ? 'flex-1' : ''} />
//                         <span className='ml-5'></span>
//                         <SignInRouteButton className={isMobile ? 'flex-1' : ''} />
//                     </div>

//                 </div>
//                 <div className={isMobile ?
//                     'flex flex-row mt-5 max-w-full images' :
//                     'flex flex-row mt-5 col-span-2 images'}>
//                     <img
//                         // className='max-h-96'
//                         src={PhotoMobileMockup1}
//                         alt="Mobile Mockup"
//                     />
//                     <img
//                         // className='max-h-96'
//                         src={PhotoMobileMockup2}
//                         alt="Mobile Mockup"
//                     />
//                 </div>
//             </div>

//         </div>
//     )
// }



// const PropSolutions = () => {
//     const isMobile = useMediaQuery({ maxWidth: 768 });
//     const solutions = [
//         {
//             header: "Home Equity Investment",
//             img: IllustrationSmallEquity
//         },
//         {
//             header: "Purchasing",
//             img: IllustrationSmallPurchasing
//         },
//         {
//             header: "Exterior",
//             img: IllustrationSmallExterior
//         },
//         {
//             header: "Interior",
//             img: IllustrationSmallInterior
//         }
//     ]
//     return (

//         <div className={isMobile ?
//             'flex flex-col items-center p-5' :
//             'flex flex-col items-center py-10 px-5'}>
//             <h2 className="h2-styles">Property Solutions for every Homeowner</h2>
//             <div className={isMobile ?
//                 'flex flex-col items-center w-full'
//                 : 'flex flex-row items-center w-full'}>
//                 {
//                     solutions.map((item, index) => {
//                         return (
//                             <div key={item.header} className={isMobile ?
//                                 'flex flex-col justify-center items-center w-full mb-3'
//                                 : 'flex flex-col items-center ml-2 mr-2 w-1/4'}>
//                                 <img src={item.img} alt={item.header} />
//                                 <h3 className='text-center'>{item.header}</h3>
//                             </div>
//                         );
//                     })
//                 }
//             </div>
//         </div>

//     )
// }

// const FeatureSection2 = ({ title1, text1, image1, title2, text2, image2 }) => {
//     const isMobile = useMediaQuery({ maxWidth: 768 });
//     return (
//         <div className={isMobile ?
//             'flex flex-col' :
//             'grid grid-cols-2'}>
//             <div className='feature-section2-1'>
//                 <div className='flex flex-col items-center p-10'>
//                     <img src={image1} alt={title1} />
//                     <div className='mt-5'>
//                         <h2>{title1}</h2>
//                         <p className='mt-3 body-text'>{text1}</p>
//                         <SignUpRouteButton className={isMobile ?
//                             'w-full mt-3' :
//                             'mt-3'} />
//                     </div>

//                 </div>

//             </div>
//             <div className='feature-section2-2'>
//                 <div className='flex flex-col items-center p-10'>
//                     <img src={image2} alt={title2} />
//                     <div className='mt-5'>
//                         <h2>{title2}</h2>
//                         <p className='mt-3 body-text'>{text2}</p>
//                         <SignUpRouteButton className={isMobile ?
//                             'w-full mt-3' :
//                             'mt-3'} />
//                     </div>

//                 </div>

//             </div>
//         </div>
//     );
// }

// const FeatureSection3 = ({ title, text, image }) => {
//     const isMobile = useMediaQuery({ maxWidth: 768 });
//     return (
//         <div className={isMobile ?
//             'p-10' :
//             'p-20'}>
//             <div className={isMobile ?
//                 'flex flex-col items-center' :
//                 'flex flex-row-reverse items-center'}>
//                 <img className={isMobile ?
//                     '' :
//                     'flex-1 max-h-72'} src={image} alt={title} />
//                 <div className='flex-1 mt-5'>
//                     <h2>{title}</h2>
//                     <p className='mt-3 body-text'>{text}</p>
//                     <SignUpRouteButton className={isMobile ?
//                         'w-full mt-3' :
//                         'mt-3'} />
//                 </div>

//             </div>
//         </div>
//     );
// }


export default LandingPage