import "../styles/styles.scss"
import "../styles/textStyles.scss"
import '@tomtom-international/web-sdk-maps/dist/maps.css'
import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";

import { auth } from "../firebase";
import { onAuthStateChanged } from "firebase/auth";

import { GET_USER_QUERY, UPDATE_USER_MUTATION } from "../graphql/UserOperations";
import { GET_ALL_AFFILIATE_OFFERS_QUERY } from "../graphql/AffiliateOfferOperations";
import { GET_PROPERTY_DASHBOARDINFO_BY_ADDRESS_ID_QUERY } from "../graphql/PropertyInformationOperations";
import { GET_ADDRESSES_QUERY, CREATE_ADDRESS_MUTATION } from "../graphql/AddressOperations";
import { useQuery, useMutation } from "@apollo/client";

// import tt from '@tomtom-international/web-sdk-maps';
import { capitalizeFirstLetter, capitalizeObjectValues, formatDollarAmount, formatLargeDollarAmount, extractNumbersFromString } from "../utils/stringTransformer";
import { convertToImperial, getImperialUnit } from "../utils/unitConverter";

import { BeatLoader } from 'react-spinners';
import TabMenu from "../components/widgets/TabMenu";
import OwnershipInfo from "../components/dashboard/OwnershipInfo";
import PropertyInfo from "../components/dashboard/PropertyInfo";
import Valuation from "../components/dashboard/Valuation";
// import AffiliateQuickDashboard from "../components/affiliate/AffiliateQuickDashboard";

const Dashboard = () => {

  const [loading, setLoading] = useState(true);
  const [addressFound, setAddressFound] = useState(false);

  const [loginEmail, setLoginEmail] = useState(null);
  const [addressId, setAddressId] = useState(null);

  const [address, setAddress] = useState({});
  const [mapImageUrl, setMapImageUrl] = useState(null);
  const [propertyInfo, setPropertyInfo] = useState({});
  const [ownershipInfo, setOwnershipInfo] = useState({});
  const [valuation, setValuation] = useState({});
  const [affiliates, setAffiliates] = useState([]);

  const { data: affiliateOffersData } = useQuery(GET_ALL_AFFILIATE_OFFERS_QUERY);
  const { data: propertyInfoData } = useQuery(GET_PROPERTY_DASHBOARDINFO_BY_ADDRESS_ID_QUERY, {
    variables: { addressId: addressId }
  });
  const { data: addressesData } = useQuery(GET_ADDRESSES_QUERY, {
    variables: { userLoginEmail: loginEmail },
  });

  useEffect(() => {

    const listen = onAuthStateChanged(auth, (user) => {
      if (user) {
        setLoginEmail(user.email);
      }
    });

    if (affiliateOffersData) {
      console.log('affiliateOffersData', affiliateOffersData);
      if (affiliateOffersData.getAllAffiliateOffers && affiliateOffersData.getAllAffiliateOffers.length > 0) {
        const affiliates = [];
        affiliateOffersData.getAllAffiliateOffers.forEach(affiliate => {
          affiliates.push({
            logoImgSrc: affiliate.logoUrl500x200,
            type: affiliate.offerType,
            qualification: 'Very likely qualified',
            bulletPoints: affiliate.bulletPoints,
            link: affiliate.offerUrl
          });
        });
        setAffiliates(affiliates);
      }
    }

    if (addressesData) {
      console.log('addressesData', addressesData);
      // setAddressFound(addressesData.getAddresses.length > 0);
      // console.log('addressFound', addressFound);
      const addresses = addressesData.getAddresses;
      if (addressFound === true) {
        setMapImageUrl(addressesData.getAddresses[0].propertyMapUrl);
        const address = addresses[0];
        const addressLine1 = capitalizeFirstLetter(address.streetAddress, { capitalizeDirections: true }) + (address.unit ? ' ' + capitalizeFirstLetter(address.unit) : '');
        const addressLine2 = capitalizeFirstLetter(address.locality) + ', ' + address.regionCode + ' ' + address.postalCode;
        setAddressId(address.id);
        const addr = { line1: addressLine1, line2: addressLine2 };
        setAddress(addr)

      }




      if (propertyInfoData && propertyInfoData.getAssessmentByAddressId) {
        const info = { ...propertyInfoData.getAssessmentByAddressId };

        // Ownership Info
        const ownershipInfo = {};
        ownershipInfo['estimatedValue'] = formatLargeDollarAmount(info.valuationValueHigh);
        ownershipInfo['activity30D'] = 'None';
        if (info.owner) {
          ownershipInfo['ownershipInfo'] = {};
          console.log('info.owner', info.owner);
          info.owner.names.forEach((name, index) => {
            let ownername = '';
            if (name.fullName) {
              ownername = name.fullName;
            } else if (name.firstName && name.lastName) {
              ownername = name.firstName + ' ' + name.lastName;
            } else if (name.firstName) {
              ownername = name.firstName;
            } else if (name.lastName) {
              ownername = name.lastName;
            }
            ownershipInfo['ownershipInfo']['Owner Name ' + (index + 1)] = capitalizeFirstLetter(ownername);
          });
        }
        var mailingAddress = '';
        if (info.owner.streetAddress) {
          mailingAddress += capitalizeFirstLetter(info.owner.streetAddress, { capitalizeDirections: true });
        }
        if (info.owner.locality) {
          mailingAddress += ', ' + capitalizeFirstLetter(info.owner.locality);
        }
        if (info.owner.regionCode) {
          mailingAddress += ', ' + info.owner.regionCode;
        }
        if (info.owner.postalCode) {
          mailingAddress += ' ' + info.owner.postalCode;
        }
        ownershipInfo['ownershipInfo']['Mailing Address'] = mailingAddress;

        if (info.owner.ownershipStatusDescription) {
          ownershipInfo['ownershipInfo']['Ownership Status'] = capitalizeFirstLetter(info.owner.ownershipStatusDescription);
          if (info.owner.ownershipStatusCode) {
            ownershipInfo['ownershipInfo']['Ownership Status'] += ' (' + info.owner.ownershipStatusCode + ')';
          }
        }

        setOwnershipInfo(ownershipInfo);

        // Property Info
        const propertyInfo = {};
        if (info.primaryStructure) {

          const infoWithCapitalizedValues = capitalizeObjectValues({ ...info.primaryStructure });

          const unitArea = info.unitArea ? info.unitArea : undefined;
          if (infoWithCapitalizedValues.livingArea)
            propertyInfo['Living Area'] = convertToImperial(infoWithCapitalizedValues.livingArea, unitArea).toFixed(1) + ' ' + getImperialUnit(unitArea);
          if (infoWithCapitalizedValues.buildingLotRatio)
            propertyInfo['Buiding/Lot Ratio'] = infoWithCapitalizedValues.buildingLotRatio;
          if (infoWithCapitalizedValues.yearBuilt)
            propertyInfo['Year Built'] = infoWithCapitalizedValues.yearBuilt;
          if (infoWithCapitalizedValues.yearRenovated)
            propertyInfo['Year Renovated'] = infoWithCapitalizedValues.yearRenovated;
          if (infoWithCapitalizedValues.storiesCount)
            propertyInfo['Stories'] = infoWithCapitalizedValues.storiesCount;
          if (infoWithCapitalizedValues.foundationDescription)
            propertyInfo['Foundation'] = infoWithCapitalizedValues.foundationDescription;
          if (infoWithCapitalizedValues.elevator)
            propertyInfo['Elevator'] = infoWithCapitalizedValues.elevator;
          if (infoWithCapitalizedValues.basementDescription)
            propertyInfo['Basement'] = infoWithCapitalizedValues.basementDescription;
          if (infoWithCapitalizedValues.exteriorWallDescription)
            propertyInfo['Exterior Wall'] = infoWithCapitalizedValues.exteriorWallDescription;
          if (infoWithCapitalizedValues.fireplace)
            propertyInfo['Fireplace'] = infoWithCapitalizedValues.fireplace;
          if (infoWithCapitalizedValues.heatingDescription)
            propertyInfo['Heating'] = infoWithCapitalizedValues.heatingDescription;
          if (infoWithCapitalizedValues.constructionDescription)
            propertyInfo['Construction'] = infoWithCapitalizedValues.constructionDescription;
          if (infoWithCapitalizedValues.roofDescription)
            propertyInfo['Roof'] = infoWithCapitalizedValues.roofDescription;
          if (infoWithCapitalizedValues.styleDescription)
            propertyInfo['Style'] = infoWithCapitalizedValues.styleDescription;
          if (infoWithCapitalizedValues.airConditioningDescription)
            propertyInfo['Air Conditioning'] = infoWithCapitalizedValues.airConditioningDescription;
          if (infoWithCapitalizedValues.parkingSpaces)
            propertyInfo['Parking Spaces'] = infoWithCapitalizedValues.parkingSpaces;
          if (infoWithCapitalizedValues.garageDescription)
            propertyInfo['Garage'] = infoWithCapitalizedValues.garageDescription;
          if (infoWithCapitalizedValues.numberOfBuildings)
            propertyInfo['Number of Buildings'] = infoWithCapitalizedValues.numberOfBuildings;
          if (infoWithCapitalizedValues.units)
            propertyInfo['Units'] = infoWithCapitalizedValues.units;
          if (infoWithCapitalizedValues.rooms)
            propertyInfo['Total Rooms'] = infoWithCapitalizedValues.rooms;
          if (infoWithCapitalizedValues.bedrooms)
            propertyInfo['Bedrooms'] = infoWithCapitalizedValues.bedrooms;
          if (infoWithCapitalizedValues.baths)
            propertyInfo['Bathrooms'] = infoWithCapitalizedValues.baths;
        }

        setPropertyInfo(propertyInfo);

        // Valuation
        const valuation = {};
        valuation.taxYear = info.taxYear ? info.taxYear : '-';
        valuation.propertyTax = info.taxAmount ? formatDollarAmount(info.taxAmount) : '-';
        valuation.taxRateCode = info.taxRateAreaCode ? info.taxRateAreaCode : '-';
        if (info.valuationValueLow && info.valuationValueHigh) {
          valuation.valuationMin = formatLargeDollarAmount(info.valuationValueLow);
          valuation.valuationMax = formatLargeDollarAmount(info.valuationValueHigh);
        } else {
          valuation.valuationMin = '-';
          valuation.valuationMax = '-';
        }
        setValuation(valuation);
      }
      setAddressFound(addressesData.getAddresses.length > 0);
      setLoading(false);
    }
    return () => {

      listen();
    }
  }, [affiliateOffersData,
    addressesData,
    propertyInfoData,
    loading,
    addressFound,

  ]);

  return (
    <div className="flex flex-col items-center mt-10">
      {loading ?
        <DashboardLoading /> :
        addressFound === false ?
          <UnlockDashboard loginEmail={loginEmail} /> :
          <DashboardContent
            address={address}
            mapImageUrl={mapImageUrl}
            affiliates={affiliates}
            propertyInfo={propertyInfo}
            ownershipInfo={ownershipInfo}
            valuation={valuation}
          />
      }

    </div>

  )
}

const DashboardLoading = () => {
  return (
    <div className="text-center">
      <BeatLoader color="#ccc" loading={true} />
    </div>
  );
}


const UnlockDashboard = ({ loginEmail }) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const [initialUserDataFetched, setInitialUserDataFetched] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [showPersonalDataForm, setShowPersonalDataForm] = useState(true);
  const [confirmPersonalDataInformationValid, setConfirmPersonalDataInformationValid] = useState(false);
  const [confirmAddressDataInformationValid, setConfirmAddressDataInformationValid] = useState(false);

  const [firstNameInput, setFirstNameInput] = useState(null); // input value
  const [lastNameInput, setLastNameInput] = useState(null); // input value
  const [contactEmailInput, setContactEmailInput] = useState(loginEmail); // input value
  const [phoneNumberInput, setPhoneNumberInput] = useState(null); // input value

  const [newStreetAddress, setNewStreetAddress] = useState(null);
  const [newUnitNumber, setNewUnitNumber] = useState(null);
  const [newCity, setNewCity] = useState(null);
  const [newState, setNewState] = useState(null);
  const [addressErrorMessage, setAddressErrorMessage] = useState(null);

  const { data: userData } = useQuery(GET_USER_QUERY, {
    variables: { email: loginEmail },
  });
  const [updateUser] = useMutation(UPDATE_USER_MUTATION);
  const [createAddress] = useMutation(CREATE_ADDRESS_MUTATION);

  useEffect(() => {

    if (userData && userData.getUser && !initialUserDataFetched) {
      setInitialUserDataFetched(true);
      const user = userData.getUser;
      setFirstNameInput(user.firstName);
      setLastNameInput(user.lastName);
      setContactEmailInput(user.contactEmail ? user.contactEmail : loginEmail);
      setPhoneNumberInput(user.phoneNumber);

      if (user.firstName && user.firstName.length > 0 &&
        user.lastName && user.lastName.length > 0 &&
        user.contactEmail && user.contactEmail.length > 0
      ) {
        setShowPersonalDataForm(false);
      }
    }


    // confirm personal data information
    if ((firstNameInput && firstNameInput.length > 0) ||
      (lastNameInput && lastNameInput.length > 0) ||
      (contactEmailInput && contactEmailInput.length > 0) ||
      (phoneNumberInput && phoneNumberInput.length > 0)) {

      const firstNameValid = firstNameInput && firstNameInput.length > 0;
      const lastNameValid = lastNameInput && lastNameInput.length > 0;
      const contactEmailValid = contactEmailInput && contactEmailInput.length > 0;
      setConfirmPersonalDataInformationValid(
        firstNameValid && lastNameValid && contactEmailValid && !updating
      );
    }


    // confirm address data information

    if ((newStreetAddress && newStreetAddress.length > 0) ||
      (newCity && newCity.length > 0) ||
      (newState && newState.length > 0)) {
      const newStreetAddressValid = newStreetAddress && newStreetAddress.length > 0;
      const newCityValid = newCity && newCity.length > 0;
      const newStateValid = newState && newState.length > 0;
      setConfirmAddressDataInformationValid(
        newStreetAddressValid && newCityValid && newStateValid && !updating
      );
    }
  }, [firstNameInput,
    lastNameInput,
    contactEmailInput,
    phoneNumberInput,
    newStreetAddress,
    newUnitNumber,
    newCity,
    newState,
    userData,
    initialUserDataFetched,
    loginEmail,
    updating]);

  const updateUserInformation = async () => {
    return await updateUser({
      variables:
      {
        loginEmail: loginEmail,
        firstName: firstNameInput,
        lastName: lastNameInput,
        contactEmail: contactEmailInput,
        phone: phoneNumberInput
      }
    });
  }

  return (
    <div className="flex flex-col px-5 max-w-screen-xl">
      <h1>Unlock your Dashboard</h1>
      <h3>Please provide us with some more information so that we can fully unlock your dashboard.</h3>
      {showPersonalDataForm ?
        <div>
          <div className={isMobile ?
            'flex flex-col-reverse items-center my-10' :
            'grid grid-cols-2 items-center my-10'}>

            <form className="w-full">
              <h2>Personal Information</h2>
              <p className='input-field-label mt-5'>First Name</p>
              <input
                className='input-field w-full mt-2'
                placeholder='First Name'
                value={firstNameInput || ''}
                onChange={(e) => {
                  setFirstNameInput(e.target.value);
                }}
              />

              <p className='input-field-label mt-5'>Last Name</p>
              <input className='input-field w-full mt-2'
                placeholder='Last Name'
                value={lastNameInput || ''}
                onChange={(e) => {
                  setLastNameInput(e.target.value);
                }}
              />

              <p className='input-field-label mt-5'>Contact Email (may differ from your login email)</p>
              <input className='input-field w-full mt-2'
                placeholder='Email Address'
                value={contactEmailInput || ''}

                onChange={(e) => {
                  setContactEmailInput(e.target.value);
                }}
              />

              <p className='input-field-label mt-5'>Phone Number (optional)</p>
              <input className='input-field w-full mt-2'
                placeholder='Phone Number'
                value={phoneNumberInput || ''}

                onChange={(e) => {
                  setPhoneNumberInput(e.target.value);
                }}
              />


              <button className={
                confirmPersonalDataInformationValid ?
                  'filled-button w-full mt-5' :
                  'inactive-filled-button w-full mt-5'
              }
                onClick={async (e) => {
                  e.preventDefault();
                  if (!updating) {
                    setUpdating(true);
                    console.log('updating user information');
                    const res = await updateUserInformation();

                    if (res && res.data && res.data.updateUser) {
                      console.log('user information updated', res.data.updateUser);
                      setUpdating(false);
                      setShowPersonalDataForm(false);
                    } else {
                      console.log('user information update failed');
                      setUpdating(false);
                    }
                  }
                }}>
                {updating ? <BeatLoader color="#ccc" loading={true} /> : 'Update Information'}
                {/* Update Information */}
              </button>

            </form>
            <div className="px-5 flex flex-col items-center">
              <img
                className="max-h-96"
                src="https://storage.googleapis.com/praxi-files-frontend/content/person.jpg"
                alt="Property"
              />
            </div>

          </div>
        </div> :
        <div className={isMobile ?
          'flex flex-col-reverse items-center my-10' :
          'grid grid-cols-2 items-center my-10'}>

          <form>
            <h2>Address</h2>
            <input className='input-field w-full mt-2'
              placeholder='New street address'
              value={newStreetAddress ? newStreetAddress : ''}
              onChange={(e) => {
                setNewStreetAddress(e.target.value);
              }}

            />
            <input className='input-field w-full mt-2'
              placeholder='Unit Number (optional)'
              value={newUnitNumber ? newUnitNumber : ''}
              onChange={(e) => {
                setNewUnitNumber(e.target.value);
              }}
            />
            <input className='input-field w-full mt-2'
              placeholder='City'
              value={newCity ? newCity : ''}
              onChange={(e) => {
                setNewCity(e.target.value);
              }}
            />
            <input className='input-field w-full mt-2'
              placeholder='State'
              value={newState ? newState : ''}
              onChange={(e) => {
                setNewState(e.target.value);
              }}
            />
            <p className="text-red-500">{addressErrorMessage ? addressErrorMessage : ""}</p>
            <button className={confirmAddressDataInformationValid ?
              'filled-button w-full mt-5' :
              'inactive-filled-button w-full mt-5'
            }
              onClick={async (e) => {
                e.preventDefault();
                if (!updating) {
                  setAddressErrorMessage(null);
                  setUpdating(true);

                  try {
                    const res = await createAddress({
                      variables:
                      {
                        userLoginEmail: loginEmail,
                        street: newStreetAddress,
                        city: newCity,
                        state: newState,
                        unit: extractNumbersFromString(newUnitNumber),
                      }
                    });

                    setUpdating(false);
                    if (res && res.data && res.data.createAddressByFields) {
                      console.log('address created', res.data.createAddressByFields);
                      window.location.reload();
                    }
                  } catch (error) {
                    setUpdating(false);
                    setAddressErrorMessage(error.message);
                  }
                }
              }}
            >
              {updating ? <BeatLoader color="#ccc" loading={true} /> : 'Update Property Information'}
            </button>
          </form>
          <div className="p-5">
            <img
              className=""
              src="https://storage.googleapis.com/praxi-files-frontend/usersData/default-propmap.jpg"
              alt="Property"
            />
          </div>

        </div>

      }

    </div>
  );
}



const DashboardContent = ({ address, mapImageUrl, affiliates, propertyInfo, ownershipInfo, valuation }) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const content = {
    'Overview': <OwnershipInfo
      estimatedValue={ownershipInfo.estimatedValue ? ownershipInfo.estimatedValue : '-'}
      activity30D={ownershipInfo.activity30D ? ownershipInfo.activity30D : '-'}
      content={ownershipInfo.ownershipInfo ? ownershipInfo.ownershipInfo : {}}
    />,
    'Property Info': <PropertyInfo content={propertyInfo} />,
    'Valuation': <Valuation content={valuation} />,
  };

  return (
    <div className={isMobile ?
      'flex flex-col items-center px-5' :
      "grid grid-cols-3 max-w-screen-xl py-5"}>
      {/* Dashboard Content */}

      <div className={affiliates && affiliates.length > 0 ?
        isMobile ? '' : "col-span-2 px-5" :
        "col-span-3 px-5"}>
        {/* Address */}
        <div className="">
          <h3>Property Address</h3>
          <p className="body-text">{address.line1}</p>
          <p>{address.line2}</p>

          <div className="flex flex-col items-center">
            <img
              className=" mt-5"
              src={mapImageUrl ? mapImageUrl : 'https://storage.googleapis.com/praxi-files-frontend/usersData/default-propmap.jpg'}
              alt="Property"
              onError={(event) => {
                event.target.src = 'https://storage.googleapis.com/praxi-files-frontend/usersData/default-propmap.jpg';
              }}
            />
          </div>

          {/* https://storage.googleapis.com/praxilock_public/content/Illustration-House-Placeholder.jpg */}
        </div>
        <div>
          <TabMenu className="mt-5" content={content} />
        </div>
      </div>
      {/* Dashboard Affiliate Offers */}
      {/* <div className={isMobile ?
        'mt-10 w-full' :
        "col-span-1 px-5"}>
        <h2>Affiliate Offers</h2>
        <div>
          {affiliates.map((affiliate, index) => (
            <AffiliateQuickDashboard
              key={index}
              logoImgSrc={affiliate.logoImgSrc}
              type={affiliate.type}
              qualification={affiliate.qualification}
              bulletPoints={affiliate.bulletPoints}
              link={affiliate.link}
            />
          ))}
        </div>
      </div> */}
    </div>
  );
}



export default Dashboard;


