import "../../styles/widgetStyles.scss"
import "../../styles/textStyles.scss"


import React, { useState } from 'react'
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../firebase";

import { useMutation } from '@apollo/client';
import { CREATE_USER_MUTATION } from "../../graphql/UserOperations";

const SignUpForm = () => {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState(null);

    const [createNewUser] = useMutation(CREATE_USER_MUTATION);

    const createUser = async (email) => {
        const { data, error } = createNewUser({
            variables: { email: email },
        });
    
        if (error) {
            console.log(error);
        }
    
        return data;
    };

    const signUp = (e) => {
        e.preventDefault();
        createUserWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Signed in 
                setErrorMessage(null);
                // const user = userCredential.user;

                createUser(email).then((result) => {
                    return result;
                }).catch((error) => {
                    console.log(error);
                });
                // ...
            }).catch((error) => {
                if (error.code === 'auth/email-already-in-use') {
                    setErrorMessage('That email address is already in use!')
                } else if (error.code === 'auth/invalid-email') {
                    setErrorMessage('That email address is invalid!')
                } else if (error.code === 'auth/weak-password') {
                    setErrorMessage('That password is too weak!')
                } else if (error.code === 'auth/operation-not-allowed') {
                    setErrorMessage('That operation is not allowed!')
                } else {
                    setErrorMessage('An unknown error occurred!')
                }
            });
    }

    return (
        <div className="signup-container">
            <form onSubmit={signUp}>
                <div className="flex flex-col">
                    <label className="input-field-label mt-10">Email address</label>
                    <input
                        className="input-field"
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <label className="input-field-label mt-5">Password</label>
                    <input
                        className="input-field"
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <p className="text-red-500">{errorMessage ? errorMessage : ""}</p>
                    <button className="filled-button mt-5" type="submit">Sign Up</button>
                </div>

            </form>
        </div>
    )
}

export default SignUpForm