import React, { useEffect, useState } from "react";
import './App.scss'
// import 'bootstrap/dist/css/bootstrap.min.css';

import SignInPage from "./pages/SignInPage";
import SignUpPage from "./pages/SignUpPage";
import Profilepage from "./pages/ProfilePage";
import LandingPage from "./pages/LandingPage";
import Dashboard from "./pages/Dashboard";
import Explore from "./pages/Explore";
import Contact from "./pages/Contact";
import About from "./pages/About";
import HomeEquityInvestments from "./pages/explore/HomeEquityInvestments";
import HomeImprovementSolutions from "./pages/explore/HomeImprovementSolutions";
import HomePurchasingSolutions from "./pages/explore/HomePurchasingSolutions";
import InteriorSolutions from "./pages/explore/InteriorSolutions";

// import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./firebase";

// import SignupPage from "./pages/SignupPage";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
// import MyNavbar from "./components/MyNavbar";

import { ApolloClient, InMemoryCache, ApolloProvider, HttpLink, from, } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import Terms from "./pages/Terms";
import FeatureDescriptionTitleMonitoring from "./pages/FeatureDescriptionTitleMonitoring";
import FeatureDescriptionPropertyReports from "./pages/FeatureDescriptionPropertyReports";
// import { onError } from '@apollo/client/link/error';

const uriGraphqlServerDatahandler = process.env.NODE_ENV === 'development' ?
  `${process.env.REACT_APP_GRAPHQL_DATAHANDLER_SERVER_URL_DEVELOPMENT}` :
  process.env.NODE_ENV === 'production' ?
    `${process.env.REACT_APP_GRAPHQL_DATAHANDLER_SERVER_URL_PRODUCTION}` :
    `${process.env.REACT_APP_GRAPHQL_DATAHANDLER_SERVER_URL_TEST}`;


// Create an HTTP Link with the GraphQL server URL
// const httpLink = new HttpLink({
//   uri: uriGraphqlServerDatahandler,
//   credentials: 'include',
// });

// // Create an Apollo Link that adds the bearer token to the headers
// const authLink = setContext((_, { headers }) => {
//   // Get the bearer token from wherever it is stored (e.g., from your authentication state)
//   const token = '';

//   // Return the headers with the bearer token
//   return {
//     headers: {
//       ...headers,
//       Authorization: token ? `Bearer ${token}` : '',
//     },
//   };
// });

// const errorLink = onError(({ graphQLErrors, networkError }) => {
//   if (graphQLErrors) {
//     // Handle GraphQL errors (e.g., log or display error messages)
//     console.log(graphQLErrors);
//   }
//   if (networkError) {
//     // Handle network errors (e.g., log or display error messages)
//     console.log(networkError);
//   }
// });

// // Combine the links (error handling, authorization, and HTTP) into a single link
// const link = from([errorLink, authLink, httpLink]);

// const client = new ApolloClient({
//   cache: new InMemoryCache(),
//   link: link,
// });

function App() {
  const [authToken, setAuthToken] = useState(null);

  useEffect(() => {
    const currentUser = auth.currentUser;
    if (currentUser) {
      currentUser.getIdToken(true).then((idToken) => {
        setAuthToken(idToken);
      });
    }
  });

  // Authentication link
  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        Authorization: authToken ? `Bearer ${authToken}` : '',
      },
    };
  });

  // Http link
  // Create an HTTP Link with the GraphQL server URL
  const httpLink = new HttpLink({
    uri: uriGraphqlServerDatahandler,
    credentials: 'include',
  });

  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: from([authLink, httpLink]),
  });

  return (
    <ApolloProvider client={client}>
      <BrowserRouter>
        <div className="app-container">
          <Navbar />
          {/* <MyNavbar /> */}
          <div className="content-container">
            <Routes>
              <Route path="/" element={<LandingPage />} />
              <Route path="/signin" element={<SignInPage />} />
              <Route path="/signup" element={<SignUpPage />} />
              <Route path="/profile" element={<Profilepage />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/explore" element={<Explore />} />
              <Route path="/explore/home-equity-investments" element={<HomeEquityInvestments />} />
              <Route path="/explore/home-improvement-solutions" element={<HomeImprovementSolutions />} />
              <Route path="/explore/home-purchasing-solutions" element={<HomePurchasingSolutions />} />
              <Route path="/explore/interior-solutions" element={<InteriorSolutions />} />
              <Route path="/feature-title-monitoring" element={<FeatureDescriptionTitleMonitoring />} />
              <Route path="/feature-property-reports" element={<FeatureDescriptionPropertyReports />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/about" element={<About />} />
              <Route path="/terms" element={<Terms />} />
            </Routes>
          </div>
          <div className="z-1">
            <Footer />
          </div>

        </div>
      </BrowserRouter>
    </ApolloProvider >
  );
}

export default App;
