import React from "react";
import SliderMenu from "../components/widgets/SliderMenu";

const Explore = () => {
    return (
        <div className='flex flex-col items-center'>
            <SliderMenu />
            <h2>Explore</h2>
        </div>
    )
}

export default Explore;
