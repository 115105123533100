import "../../styles/widgetStyles.scss"

import React, { useState } from 'react'
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../firebase";


const SignInForm = () => {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState(null);

    const signIn = (e) => {
        e.preventDefault();
        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Signed in 
                setErrorMessage(null);
                const user = userCredential.user;
                console.log(user);
                // ...
            }).catch((error) => {
                if(error.code === 'auth/invalid-email') {
                    setErrorMessage('That email address is invalid')
                } else if(error.code === 'auth/user-disabled') {
                    setErrorMessage('That user account is currently disabled')
                } else if(error.code === 'auth/user-not-found') {
                    setErrorMessage('User not found')
                } else if(error.code === 'auth/wrong-password') {
                    setErrorMessage('Wrong password')
                } else {
                    setErrorMessage('An unknown error occurred!')
                }
            });
    }

    return (
        <div className="signin-container">
            <form onSubmit={signIn}>
                <div className="flex flex-col">
                    <label className="input-field-label mt-10">Email address</label>
                    <input
                        className="input-field"
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <label className="input-field-label mt-5">Password</label>
                    <input
                        className="input-field"
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <p className="text-red-500">{errorMessage ? errorMessage : ""}</p>
                    <button className="filled-button mt-5" type="submit">Login</button>
                </div>

            </form>
        </div>
    )
}

export default SignInForm