import '../../styles/components.scss'

import React from 'react';
import { useMediaQuery } from "react-responsive";

import HighlightedInfo from './HighlightedInfo';
const OwnershipInfo = ({ estimatedValue, activity30D, content }) => {
    const isMobile = useMediaQuery({ maxWidth: 768 });
    const contentEntries = Object.entries(content);
    return (
        <div>
            <div className={isMobile ?
                'flex flex-col' :
                'flex flex-row'}>
                <div className={isMobile ?
                    'flex-1 mb-5' :
                    'flex-1 mr-5'}>
                    <HighlightedInfo title='Estimated Value' value={estimatedValue} />
                </div>
                <div className='flex-1'>
                    <HighlightedInfo title='30 Day Title Activity' value={activity30D} />
                </div>
            </div>
            <div className='mt-10'>
                <h3>Ownership Information</h3>
                {contentEntries.map(([title, value]) => (
                    <div key={title} className='mt-5'>
                        <h6 className='data-field-label'>{title}</h6>
                        <p className='data-field-value'>{value}</p>
                    </div>
                ))}
            </div>
        </div >
    );
};

export default OwnershipInfo;