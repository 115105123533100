import '../../styles/components.scss'

import React from 'react';

const PropertyInfo = ({ content }) => {

    const contentEntries = Object.entries(content);
    return (
        <div className='mt-5 mb-5'>
            <h3>Property Characteristics</h3>
            <div className='grid grid-cols-3'>
                {contentEntries.map(([title, value]) => (
                    <div key={title} className='mt-5'>
                        <h6 className='data-field-label'>{title}</h6>
                        <p className='data-field-value'>{value}</p>
                    </div>
                ))}
            </div>

        </div>
    );
};

export default PropertyInfo;