import React from 'react'


import IllustrationHomeEquity from '../../assets/Illustration-HomeEquity.svg';
import IconFree from '../../assets/Icon-Free.svg';
import IconCentral from '../../assets/Icon-Central.svg';
import IconPersonalized from '../../assets/Icon-Personalized.svg';

const SignUpPromo = () => {
    return (
        <div className='flex flex-col items-center justify-items-center'>
            <img
                className='max-h-72'
                src={IllustrationHomeEquity}
                alt="Mobile Mockup"
            />
            <h2 className='text-center'>Welcome to your home intelligence journey</h2>
            <div className="">
                <div>
                    <div className='flex flex-row items-center justify-start mt-5'>
                        <img src={IconFree} alt="Free" />
                        <p className="body-text ml-5">Always free title monitoring</p>
                    </div>
                    <div className='flex flex-row items-center justify-start mt-5'>
                        <img src={IconCentral} alt="Central" />
                        <p className="body-text ml-5">A centralized platform for property tech solutions</p>
                    </div>
                    <div className='flex flex-row items-center justify-start mt-5'>
                        <img src={IconPersonalized} alt="Personalized" />
                        <p className="body-text ml-5">Personalized property reports</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SignUpPromo;
