import '../styles/textStyles.scss'
import React from "react";


const Contact = () => {

    return (
        <div className="flex flex-col items-center py-20">
            <h1>Contact Us</h1>
            <p className="my-5">Don't hesitate to get in touch. We are thrilled to hear any feedback and are ready to assist you with your questions.</p>
            <a className="alternate-text-link" href="mailto:contact@praxilock.com">contact@praxilock.com</a>
        </div>
    );
};

export default Contact;
