import { gql } from '@apollo/client';

/*
    Queries
*/
export const GET_ALL_AFFILIATE_OFFERS_QUERY = gql`
    query getAllAffiliateOffers {
        getAllAffiliateOffers{
            id
            partnerId
            logoUrl500x200
            title
            category
            bulletPoints
            offerUrl
            offerType
        }
    }
`;

export const GET_AFFILIATE_OFFERS_BY_CATEGORY_QUERY = gql`
    query getAffiliateOffersByCategory($category: String!) {
        getAffiliateOffersByCategory(category: $category){
            id
            partnerId
            logoUrl500x200
            title
            category
            bulletPoints
            offerUrl
            offerType
        }
    }
`;

export const GET_AFFILIATE_OFFER_BY_ID_QUERY = gql`
    query getAffiliateOfferById($id: String!) {
        getAffiliateOfferById(id: $id){
            id
            partnerId
            logoUrl500x200
            title
            category
            bulletPoints
            offerUrl
            offerType
        }
    }
`;

