import '../../styles/components.scss'

import React from 'react';
import { useMediaQuery } from "react-responsive";

const Valuation = ({ content }) => {
    const isMobile = useMediaQuery({ maxWidth: 768 });
    return (
        <div className='mt-5 mb-5'>
            <h3>Tax and Value Information</h3>
            <div className={isMobile ?
                'grid grid-cols-2 mt-5' :
                'grid grid-cols-3 mt-5'}>
                {content.taxYear ? <div>
                    <h6 className='data-field-label'>Tax Year</h6>
                    <p className='text-valuation-black'>{content.taxYear}</p>
                </div> : null}
                {content.propertyTax ? <div>
                    <h6 className='data-field-label'>Property Tax</h6>
                    <p className='text-valuation-black'>{content.propertyTax}</p>
                </div> : null}
                {content.taxRateCode ? <div>
                    <h6 className='data-field-label'>Tax Rate Code</h6>
                    <p className='text-valuation-black'>{content.taxRateCode}</p>
                </div> : null}
            </div>

            {/* {content.valuationMin && content.valuationMax ?
                <div className='mt-5'>
                    <h6 className='data-field-label'>Praxilock Valuation</h6>
                    <p className='text-valuation-primary'>{content.valuationMin} - {content.valuationMax}</p>
                </div> : null} */}


        </div>
    );

};

export default Valuation;