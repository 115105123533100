import React from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'

const PartnerQuickListItem = ({ logoSrc, title, shortDescription, url }) => {
    return (
        <div className="drop-shadow-lg bg-white p-5">
            <img className="max-h-24"
                src={logoSrc} 
                alt={`${title} logo`}
                />
            <h2 className="mt-3">{title}</h2>
            <p>{shortDescription}</p>
            <div className="mt-5 mb-5">
                <a className="outline-button" href={url} target="_blank" rel="noreferrer">
                    <span className="mr-2">Visit Site</span>
                    <FontAwesomeIcon icon={faChevronRight} />
                </a>
            </div>
        </div>
    )
}

export default PartnerQuickListItem;
